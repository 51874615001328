import { SelectOption } from 'src/app/shared/components/selector/selector.component';
import { LocalizedDict } from 'src/app/shared/localization/lozalize.models';

export const yesNoTranslations: LocalizedDict<'yes' | 'no'> = {
  yes: { EN: 'Yes', FR: 'Oui' },
  no: { EN: 'No', FR: 'Non' },
};

export const yesNoChoices: [SelectOption<true>, SelectOption<false>] = [
  {
    value: true,
    label: yesNoTranslations['yes'],
  },
  {
    value: false,
    label: yesNoTranslations['no'],
  },
];
