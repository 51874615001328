import {
  IsBoolean,
  IsDate,
  IsEnum,
  IsNumber,
  IsOptional,
  IsString,
  Validate,
} from 'class-validator';
import { LocalizedString } from '../../localization';
import { ContentSupportType, ExpositionContentType } from '../index';
import { Type } from 'class-transformer';
import { IsLocalizedString } from '../../localization/localize.dto';
export class CreateExpositionContentDTO {
  @IsString()
  @IsOptional()
  public id?: string;
  @IsEnum(ExpositionContentType)
  @IsOptional()
  public type: ExpositionContentType;

  // public sourceFile?: string;
  // public previewFile?: string;
  // public platformFile?: string;

  @IsString()
  @IsOptional()
  public name: string;

  @Validate(IsLocalizedString)
  @IsOptional()
  public description?: LocalizedString;
  @IsDate()
  @IsOptional()
  public creationDate?: Date;
  @IsString()
  @IsOptional()
  public medium?: string;
  @IsString()
  @IsOptional()
  public locale?: string;
  @IsBoolean()
  @IsOptional()
  public hasCartel?: boolean;
  @IsString()
  @IsOptional()
  public viewerPosition?: string;
  @IsString()
  @IsOptional()
  public viewerRotation?: string;
  @IsString()
  @IsOptional()
  public cartelScale?: string;
  @IsString()
  @IsOptional()
  public cartelPosition?: string;
  @IsString()
  @IsOptional()
  public cartelRotation?: string;
  @IsString()
  @IsOptional()
  public viewerScale?: string;
  @IsEnum(ContentSupportType)
  @IsOptional()
  public supportType?: ContentSupportType;
  @IsString()
  @IsOptional()
  public tags?: string;
  @IsString()
  @IsOptional()
  public targetTokenURL?: string;
  @IsNumber()
  @IsOptional()
  public priceUSD?: number;
}

export class ImportExpositionContentDTO extends CreateExpositionContentDTO {}

export type UpdateExpositionContentDTO = Partial<CreateExpositionContentDTO>;

export class UpdateArtistContentPosition {
  @IsString()
  public id: string;

  @IsString()
  public viewerPosition: string;
  @IsString()
  public viewerRotation: string;
  @IsString()
  public viewerScale: string;
  @IsString()
  @IsOptional()
  public cartelPosition?: string;
  @IsString()
  @IsOptional()
  public cartelRotation?: string;
  @IsString()
  @IsOptional()
  public cartelScale?: string;
}

export class UpdateArtistContentPositionDTO {
  @Type(() => UpdateArtistContentPosition)
  contents: UpdateArtistContentPosition[];
}
