import {
  ValidationArguments,
  ValidatorConstraint,
  ValidatorConstraintInterface,
} from 'class-validator';

@ValidatorConstraint()
export class IsLocalizedString implements ValidatorConstraintInterface {
  validate(value: unknown): boolean {
    if (!value || typeof value == 'object') {
      return true;
    }
    return false;
  }

  defaultMessage(arg?: ValidationArguments): string {
    return `${arg.property} must be a localized string object { [local]: text }`;
  }
}
