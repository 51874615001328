<div class="form-control">
  <span class="label">{{ fieldTitle | localize }}</span>
  <div class="field-with-edit">
    <div class="vector-table" *ngIf="!isEditing">
      <div class="vector" *ngFor="let ax of axis">
        <span class="label">{{ ax.localized ?? ax.axis | localize }}: </span>
        <span class="value" [style]="textStyle">
          {{ currentValue[ax.axis] }} {{ configs?.unit ?? '' }} <br />
        </span>
      </div>
    </div>
    <div class="vector-table" *ngIf="isEditing">
      <div class="vector" *ngFor="let ax of axis">
        <span class="label">{{ ax.localized ?? ax.axis | localize }}: </span>
        <input *ngIf="isEditing" [(ngModel)]="currentValue[ax.axis]" />
      </div>
    </div>
    <span
      *ngIf="!readonly"
      class="field-edit-icon"
      nz-icon
      nzType="edit"
      nzTheme="outline"
      (click)="toggleEdit()"
    ></span>
  </div>
</div>
