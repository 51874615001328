import { Injectable } from '@angular/core';
import { SupportedLocals } from '@apophenia/platform';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class LocalizeService {
  private _currentLocal$ = new BehaviorSubject<SupportedLocals>(
    SupportedLocals.EN,
  );

  get currentLocal$(): Observable<SupportedLocals> {
    return this._currentLocal$.asObservable();
  }
  get currentLocal(): SupportedLocals {
    return this._currentLocal$.value;
  }

  setLocale(lang: SupportedLocals): void {
    this._currentLocal$.next(lang);
  }
}
