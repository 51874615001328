import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ExpositionContentType } from '@apophenia/platform';
import {
  BehaviorSubject,
  Observable,
  map,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs';
import { ArtistViewModel } from 'src/app/pages/artists/artists.models';
import { ArtistsService } from 'src/app/pages/artists/artists.service';
import { SelectOption } from 'src/app/shared/components/selector/selector.component';
import { LocalizedDict } from 'src/app/shared/localization/lozalize.models';
import {
  MISSING_PICTURE_IMAGE,
  MODEL_3D_IMAGE,
} from 'src/app/shared/models/assets.models';

import { ListItemViewModel } from 'src/app/shared/models/list-items.models';
import { CurrentUsersService } from 'src/app/shared/services/current-users.service';

type ArtworkListItem = ListItemViewModel & { color: string };

const colorMap: Record<ExpositionContentType, string> = {
  [ExpositionContentType.Art]: '#161616',
  [ExpositionContentType.Audio]: '#232c23',
  [ExpositionContentType.Project]: '#2c2323',
  [ExpositionContentType.Prop]: '#24232c',
  [ExpositionContentType.Video]: '#2c2327',
};

@Component({
  selector: 'app-artists-artwork-list',
  templateUrl: './artist-artwork-list.component.html',
  styleUrls: ['./artist-artwork-list.component.scss'],
})
export class ArtistArtworkListComponent {
  artist$!: Observable<ArtistViewModel>;

  selectedExposition$ = new BehaviorSubject<string | undefined>(undefined);
  selectedArtworks$: Observable<
    Record<ExpositionContentType, ArtworkListItem[]>
  >;
  // expositions$: Observable<
  //   { id: string; name: string; artworks: ListItemViewModel[] }[]
  // >;
  contentTypes = Object.keys(ExpositionContentType) as ExpositionContentType[];
  expositionChoices$: Observable<SelectOption<string>[]>;

  translations: LocalizedDict = {
    [ExpositionContentType.Art]: { EN: 'Artworks', FR: 'Oeuvres' },
    [ExpositionContentType.Audio]: 'Audio',
    [ExpositionContentType.Prop]: { EN: 'Accessories', FR: 'Accessoires' },
    [ExpositionContentType.Project]: { EN: 'VR Projects', FR: 'Projets VR' },
    [ExpositionContentType.Video]: { EN: 'Video', FR: 'Vidéo' },
  };

  constructor(
    private artistService: ArtistsService,
    private route: ActivatedRoute,
    private currentUser: CurrentUsersService,
  ) {
    this.artist$ = this.route.params.pipe(
      map((params) => {
        const id = params.id as string;
        return id == 'current' ? this.currentUser.artistID ?? id : id;
      }),
      switchMap((id) => this.artistService.selectById(id)),
      tap((a) => {
        if (
          !this.selectedExposition$.value ||
          !a.expositions.find((x) => x.id == this.selectedExposition$.value)
        ) {
          this.selectedExposition$.next(a.activeExposition);
        }
      }),
    );
    this.expositionChoices$ = this.artist$.pipe(
      map((x) =>
        x.expositions.map((e) => ({
          label: e.name,
          value: e.id,
        })),
      ),
    );
    this.selectedArtworks$ = this.selectedExposition$.pipe(
      withLatestFrom(this.artist$),
      map(([expoID, artist]) => {
        const expo = artist.expositions.find((x) => x.id == expoID);
        const arts = expo?.content.reduce(
          (dict, a) => {
            let img: string | undefined =
              a.preview?.publicURI ?? a.optimized?.publicURI;
            if (a.source?.fileType?.includes('binary')) {
              img = MODEL_3D_IMAGE;
            }
            dict[a.type].push({
              id: a.id,
              name: a.name,
              img: img ?? MISSING_PICTURE_IMAGE,
              url: `/artist/${artist.id}/artworks/${a.id}`,
              color: colorMap[a.type],
            });
            return dict;
          },
          {
            [ExpositionContentType.Art]: [],
            [ExpositionContentType.Audio]: [],
            [ExpositionContentType.Prop]: [],
            [ExpositionContentType.Project]: [],
            [ExpositionContentType.Video]: [],
          } as Record<ExpositionContentType, ArtworkListItem[]>,
        );
        return (
          arts ??
          ({
            [ExpositionContentType.Art]: [],
            [ExpositionContentType.Audio]: [],
            [ExpositionContentType.Prop]: [],
            [ExpositionContentType.Project]: [],
            [ExpositionContentType.Video]: [],
          } as Record<ExpositionContentType, ArtworkListItem[]>)
        );
      }),
    );
  }
}
