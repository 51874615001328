import { CoreEntity } from '../core-entity';
import { Project } from '../projects';
import { reverseRecord } from '../utils';
import { AmnesicContainerColumn } from '../amnesia';

export enum OrganismCategories {
  Museum = 'Museum',
  Orchestra = 'Orchestra',
}

export enum OrganismFileTypes {
  Hub = 'Hub',
  Visual = 'Visual',
  Document = 'Document',
}

export const OrganismFilesGroups: Record<OrganismFileTypes, number> = {
  Hub: 0,
  Visual: 1,
  Document: 2,
};
export const OrganismFilesGroupsLookup = reverseRecord(OrganismFilesGroups);

export type OrganismFileType = 'Hub' | 'Visual' | 'Document';

export interface Organism extends CoreEntity {
  name: string;
  location: string;
  category: string;
  projects: Project[];
  files: AmnesicContainerColumn;
  isDev: boolean;
  freeHostingEnd: Date;
}
