import { CoreEntity } from '../core-entity';
import { LocalizedString, SupportedLocals } from '../localization';
import { Memory } from '../amnesia';

export enum ContentSupportType {
  Planar = 'Planar',
  Frame = 'Frame',
  Ground = 'Ground',
  Hanged = 'Hanged',
}

export enum ExpositionContentType {
  Art = 'Art',
  Video = 'Video',
  Audio = 'Audio',
  Project = 'Project',
  Prop = 'Prop',
}
export enum ArtistExpositionGroups {
  Thumbnail = 'Thumbnail',
  Source = 'Source',
  Preview = 'Preview',
  Optimized = 'Optimized',
}

export const ArtistExpositionMemoryGroup: Record<
  ArtistExpositionGroups,
  number
> = {
  Thumbnail: 0,
  Source: 1,
  Preview: 2,
  Optimized: 3,
};

export interface ExpositionContent extends CoreEntity {
  sourceFile?: string | Memory;
  previewFile?: string | Memory;
  platformFile?: string | Memory;
  exposition: unknown;
  type: ExpositionContentType;

  /*######################*/
  /*######################*/
  name: string;
  description: LocalizedString;
  language: SupportedLocals;
  medium: string;
  creationDate: Date;

  /*######################*/
  /*######################*/
  locale: string;
  viewerPosition: string;
  viewerRotation: string;
  viewerScale: string;
  hasCartel: boolean;
  cartelScale?: string;
  cartelPosition: string;
  cartelRotation: string;
  supportType: ContentSupportType;

  /*######################*/
  /*######################*/
  authenticID: string;
  tags: string;
  priceUSD: number;
  targetTokenURL: string;
}
