<div
  class="tabs-container"
  [class]="{ centered }"
  [style.border-bottom-color]="color"
>
  <div class="tab" *ngFor="let tab of tabChoices">
    <h2
      [class]="{ disabled: tab.disabled }"
      [style.color]="color"
      (click)="selectTab(tab.value)"
    >
      {{ tab.label }}
    </h2>
    <div
      [class]="{ separator: true, selected: currentTab === tab.value }"
      [style.background]="color"
    ></div>
  </div>
</div>
