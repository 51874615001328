import { Component, Input } from '@angular/core';
import { Size } from 'ngx-spinner';

@Component({
  selector: 'app-spinner',
  templateUrl: 'app-spinner.component.html',
})
export class AppSpinnerComponent {
  @Input() name = '';
  @Input() size: Size = 'medium';
  @Input() fullScreen = false;
}
