import { CoreEntity } from '../../core-entity';

export interface AuthCredentials extends CoreEntity {
  userID: string;
  username: string;
  email: string;
  passwordHash: string;
  passwordResetCode?: string;
  passwordResetDate?: string;
}
