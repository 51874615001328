import { MemoryEncodingRequestDTO } from '../amnesia';
import { Organism, OrganismFileTypes } from './organisms.entity';

export type CreateOrganismDTO = Pick<Organism, 'name' | 'location'> &
  Partial<Pick<Organism, 'id' | 'category' | 'freeHostingEnd' | 'isDev'>>;

export interface CreateOrganismHubDTO
  extends Omit<MemoryEncodingRequestDTO, 'isPublic' | 'groupID'> {
  memoryType: OrganismFileTypes;
}
