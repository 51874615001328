<div class="form-control">
  <span class="label">
    {{ fieldTitle | localize }}
  </span>
  <div class="field-with-edit">
    <span class="value" *ngIf="!isEditing" [style]="textStyle">
      {{ (currentLabel | localize) ?? 'None' }}
    </span>
    <nz-radio-group *ngIf="isEditing" [(ngModel)]="currentValue">
      <label nz-radio *ngFor="let option of options" [nzValue]="option.value">
        {{ option.label | localize }}
      </label>
    </nz-radio-group>
    <span
      *ngIf="!readonly"
      class="field-edit-icon"
      nz-icon
      nzType="edit"
      nzTheme="outline"
      (click)="toggleEdit()"
    ></span>
  </div>
</div>
