import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-tooltip',
  templateUrl: './tooltip.component.html',
  styleUrls: ['./tooltip.component.scss'],
})
export class TooltipComponent {
  @Input() header?: string;
  @Input() description?: string;
  @Input() learnMoreLink?: string;

  tooltipVisible = false;
  isModalVisible = false;

  hideTooltip(): void {
    this.tooltipVisible = false;
  }
}
