<div class="card user-card">
  <app-tabs [tabs]="tabsConfigs" [(currentTab)]="currentTab"></app-tabs>
  <button
    *ngIf="isAdmin$ | async"
    [routerLink]="'/users'"
    class="close-button blank"
  >
    X
  </button>
  <div class="info-tab" *ngIf="currentTab === UserTabs.Information">
    <div class="section expand" *ngIf="user$ | async; let user">
      <div class="name-id-section" [formGroup]="metadataGroup">
        <div class="name-lastname">
          <app-editable-text
            [textStyle]="{
              color: 'white',
              fontSize: '50px',
              lineHeight: '50px'
            }"
            textClass="section-title"
            formControlName="given_name"
            (currentValueChange)="updateMetadata('given_name')"
          >
          </app-editable-text>

          <app-editable-text
            [textStyle]="{
              color: 'white',
              fontSize: '50px',
              lineHeight: '50px'
            }"
            textClass="section-title"
            formControlName="family_name"
            (currentValueChange)="updateMetadata('family_name')"
          >
          </app-editable-text>
        </div>
        <span class="id-label"> ID: {{ user.id }} </span>
      </div>
      <div class="form-section"></div>
    </div>
    <!-- <div class="section img-section" *ngIf="hasImage">
      <app-image-card
        maxSize="400px"
        [img]="publicImage ?? missingImage"
        [readonly]="readonlyImage"
      ></app-image-card>
    </div> -->
  </div>
</div>
