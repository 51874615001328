<div class="file-section">
  <app-tabs
    [tabs]="tabsConfig"
    [centered]="true"
    [(currentTab)]="currentDevice"
    color="#afafaf"
  ></app-tabs>
  <app-platform-files-info
    (memoryUpdated)="memoryUpdated.emit($event)"
    [memory]="memories | getMemory : deviceMap[currentDevice]"
  >
  </app-platform-files-info>
</div>

<app-upload-tracker></app-upload-tracker>

<div class="historic-section">
  <nz-collapse>
    <nz-collapse-panel
      [nzDisabled]="true"
      [nzHeader]="translations['historic'] | localize"
    ></nz-collapse-panel>
  </nz-collapse>
</div>
