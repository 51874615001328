import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DefaultLayoutModule } from 'src/app/layout/default-layout/default-layout.module';
import { getAppRoutes } from 'src/app/layout/routes';
import { AuthGuard } from 'src/app/shared/guards/auth.guard';

const routes: Routes = [
  {
    path: '',
    runGuardsAndResolvers: 'always',
    canActivate: [AuthGuard],
    children: getAppRoutes(),
  },
  {
    path: 'login',
    loadChildren: () =>
      import('./login/login.module').then((m) => m.LoginPageModule),
  },
];

@NgModule({
  imports: [CommonModule, DefaultLayoutModule, RouterModule.forRoot(routes)],
  exports: [DefaultLayoutModule, RouterModule],
  providers: [],
})
export class LayoutModule {}
