import {
  AbstractControl,
  FormArray,
  FormGroup,
  FormControl,
  ValidatorFn,
} from '@angular/forms';

export const NotNullControl = <TValue = unknown>(
  value: TValue,
  validators?: ValidatorFn[],
): FormControl<TValue> => {
  return new FormControl<TValue>(value, { nonNullable: true, validators });
};

export type EntityFormControls<
  TEntity,
  KNestedForms extends keyof TEntity = never,
> = {
  [key in keyof Omit<TEntity, KNestedForms>]-?: undefined extends TEntity[key]
    ? FormControl<TEntity[key] | undefined>
    : FormControl<TEntity[key]>;
} & {
  [key in KNestedForms]-?: TEntity[key] extends Array<infer TArrayElement>
    ? EntityFormArray<TArrayElement>
    : EntityFormGroup<TEntity[key]>;
};
/** shorthand to defined a FormGorup type (ex: private formGroup: EntityFormGroup<Tickets> ) */
export type EntityFormGroup<
  TEntity,
  KNestedForms extends keyof TEntity = never,
> = FormGroup<EntityFormControls<Exclude<TEntity, undefined>, KNestedForms>>;

/** shorthand to defined a FormArray type (ex: get formArray(): EntityFormArray<Products> {} ) */
export type EntityFormArray<
  TEntity,
  KNestedForms extends keyof TEntity = never,
> = FormArray<EntityFormGroup<TEntity, KNestedForms>>;

/** common interface for conditional control handling.
 * The type is a Record<keyof TEntity, U>, where U is one of the following options:
 * - when "keyof TEntity" points to an array property, U can be an array or a number.
 *   A number will validate if the array is at least of length U, an array will try to find any matching elements.
 *
 * - when "keyof TEntity" points to anything else, U can be either TEntity[keyof TEntity] or an array to match against an exact value.
 *
 * - for all cases, a boolean can be used to match against zero-length arrays and nullish values
 *
 *  job offer example: { salaryType: 'ANNUAL' } or {salaryType: ['ANNUAL', 'HOURLY'], term: TermType.INDEFINITE}
 */
export type RequireIfOptions<TEntity> = {
  [K in keyof TEntity]?: TEntity[K] extends Array<infer _T>
    ? Array<_T> | number | boolean
    : TEntity[K] | TEntity[K][] | boolean;
};

export const isControlEmpty = (control: AbstractControl): boolean =>
  control.value === undefined ||
  control.value === null ||
  control.value === '' ||
  (typeof control == 'object' && Object.keys(control).length == 0) ||
  (Array.isArray(control.value) && (control.value?.length ?? 0) == 0);
