import { CommonModule } from '@angular/common';
import { NgModule, ValueProvider } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgZorroModule } from 'src/app/shared/components/ng-zorro.module';
import { LocalizedStringComponent } from 'src/app/shared/localization/localized-string/localized-string.component';
import {
  LocalizeDirective,
  LocalizePipe,
} from 'src/app/shared/localization/lozalize.directive';
import { LocalizationSharedInjectedDataToken } from 'src/app/shared/localization/lozalize.models';

const LanguageValueProvider: ValueProvider = {
  provide: LocalizationSharedInjectedDataToken,
  useValue: {},
};

@NgModule({
  declarations: [LocalizeDirective, LocalizePipe, LocalizedStringComponent],
  providers: [LanguageValueProvider, LocalizePipe],
  imports: [CommonModule, NgZorroModule, ReactiveFormsModule, FormsModule],
  exports: [LocalizeDirective, LocalizePipe, LocalizedStringComponent],
})
export class LocalizeModule {}
