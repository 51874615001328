import { DeviceTypes } from '../../core-entity';

export interface MemoryEncodingRequestDTO {
  isPublic: boolean;
  fileType: string;
  fileSize: number;
  fileName: string;
  device: DeviceTypes;
  id?: string;
  groupID?: number;
}

export interface MemoryEncodingResponseDTO {
  uri: string;
  memoryID: string;
  contextID: string;
}

export interface UpdateMemoryDTO extends Partial<MemoryEncodingRequestDTO> {
  id: string;
}
