<div appDragAndDropImage class="image-box" (files)="onImageAdded($event)">
  <div class="image-content">
    <h3 *ngIf="title">{{ title }}</h3>
    <img [src]="img ? img : 'assets/img/no-image.png'" />
    <app-upload-button
      *ngIf="!readonly"
      (fileEvent)="onImageAdded($event.files)"
      [iconContent]="'cloud-upload'"
    >
    </app-upload-button>
  </div>
</div>
