import { AmnesicContainerColumn } from '../amnesia';
import { CoreEntity } from '../core-entity';
import { LocalizedString } from '../localization';
import { reverseRecord } from '../utils';

export enum ModuleFileTypes {
  Scene = 'Scene',
  Visual = 'Visual',
}

export const ModuleFilesGroups: Record<ModuleFileTypes, number> = {
  Scene: 0,
  Visual: 1,
};
export const ModuleFilesGroupsLookup = reverseRecord(ModuleFilesGroups);

export enum GenericModuleType {
  Exposition = 'Exposition',
}

export interface ModulesMetadata extends CoreEntity {
  name: string;
  description: LocalizedString;
  type: GenericModuleType;
  isDev: boolean;
  platformVersion: string;
  manifests: AmnesicContainerColumn;
}
