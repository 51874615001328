<div class="app-container">
  <div class="main-background"></div>
  <!-- <div class="header-container">
    <app-admin-top-nav></app-admin-top-nav>
  </div> -->
  <app-sidebar></app-sidebar>
  <div class="page-container">
    <app-header></app-header>
    <div class="page-content">
      <router-outlet></router-outlet>
    </div>
  </div>
  <app-spinner [name]="spinnerName"></app-spinner>
</div>
