export const mergeDeep = <TEntity extends object>(
  target: TEntity,
  ...sources: Partial<TEntity>[]
): TEntity => {
  if (!sources.length) return target;
  const source = sources.shift();

  if (isObject(target) && isObject(source)) {
    for (const key in source) {
      if (isObject(source[key])) {
        if (!target[key])
          Object.assign(target, {
            [key]: {},
          });
        mergeDeep(
          target[key] as object,
          source[key] as Partial<
            TEntity[Extract<keyof NonNullable<Partial<TEntity>>, string>]
          >,
        );
      } else {
        Object.assign(target, {
          [key]: source[key],
        });
      }
    }
  }
  return mergeDeep(target, ...sources);
};

export const isObject = (item: unknown): boolean => {
  return (
    item != undefined &&
    typeof item === 'object' &&
    !Array.isArray(item) &&
    !(item instanceof Date)
  );
};
