<div class="col">
  <app-action-bar> </app-action-bar>
  <div class="row row-wrap">
    <button
      *ngFor="let user of users$ | async"
      class="card users-card"
      [routerLink]="user.url"
    >
      <div class="card-img">
        <img [src]="user.img" />
      </div>
      <div class="title">
        {{ user.name }}
      </div>
    </button>
  </div>
</div>
