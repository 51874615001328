import { Component, Input, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { LocalizedString } from '@apophenia/platform';
import { EditableFormItem } from 'src/app/shared/components/dynamic-form-items/_editable-form';

type Vector = Record<string, number>;

export interface VectorAxis {
  axis: string;
  localized?: LocalizedString;
}
export interface VectorConfigs {
  scale?: number;
  unit?: string;
}

@Component({
  selector: 'app-editable-vector',
  templateUrl: './editable-vector.component.html',
  styleUrls: ['./editable-vector.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => EditableVectorComponent),
      multi: true,
    },
  ],
})
export class EditableVectorComponent extends EditableFormItem<Vector, string> {
  @Input() textStyle?: Partial<CSSStyleDeclaration>;
  @Input() axis: VectorAxis[] = [{ axis: 'x' }, { axis: 'y' }, { axis: 'z' }];
  @Input() configs?: VectorConfigs = { scale: 1, unit: '' };
  @Input() precision = 4;
  @Input() separator = ',';

  protected override transformInput(value: string): Vector {
    const vector = (value ?? '').split(',').reduce((vec, value, dim) => {
      vec[this.axis[dim].axis] =
        parseFloat(value) * (this.configs?.scale ?? 1.0);
      return vec;
    }, {} as Vector);
    for (const axis of this.axis) {
      if (!vector[axis.axis]) {
        vector[axis.axis] = 0;
      }
    }
    return vector;
  }

  protected override transformOutput(value: Vector): string {
    return Object.keys(value)
      .map((x) =>
        (value[x] / (this.configs?.scale ?? 1.0)).toFixed(this.precision),
      )
      .join(this.separator);
  }
}

// @Pipe({ name: 'vector' })
// export class StringVectorPipe implements PipeTransform {
//   transform(value: any): string {
//     return undefined;
//   }
// }
