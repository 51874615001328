<div class="info-tab">
  <div class="section expand">
    <div class="name-id-section" [formGroup]="formGroup">
      <app-editable-text
        [readonly]="readonly"
        [textStyle]="{
          color: 'white',
          fontSize: '50px',
          lineHeight: '50px'
        }"
        textClass="section-title"
        formControlName="name"
        placeholder="Nom"
        (currentValueChange)="nameChanged.emit()"
      >
      </app-editable-text>
      <span class="id-label" *ngFor="let label of extraInfoLabels">
        {{ label }}
      </span>
    </div>
    <div class="form-section">
      <ng-content></ng-content>
    </div>
  </div>
  <div class="section img-section" *ngIf="hasImage">
    <app-image-card
      maxSize="400px"
      [img]="publicImage?.publicURI ?? missingImage"
      [readonly]="readonlyImage"
      (fileChanged)="onImageChange($event)"
    ></app-image-card>
  </div>
</div>
