import { ExpositionContent } from './exposition-content.entity';
import { CoreEntity } from '../core-entity';
import { Memory } from '../amnesia';
import { LocalizedString } from '../localization';

export interface ArtistExposition extends CoreEntity {
  name: string;
  description: LocalizedString;
  content?: ExpositionContent[];
  memoryContext: Memory[];
  artist?: unknown;
  artistID: string;
}
