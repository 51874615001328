import { CommonModule } from '@angular/common';
import { NgModule, inject } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CanActivateFn, Router, RouterModule } from '@angular/router';
import { ApiTokenPayload } from '@apophenia/platform';
import { Observable, map, withLatestFrom } from 'rxjs';
import { RouteAccessLevel, RouteInfo } from 'src/app/layout/routes';
import { ArtistArtworkCardComponent } from 'src/app/pages/artists/artist-artwork-card/artist-artwork-card.component';
import { ArtistCartelComponent } from 'src/app/pages/artists/artist-artwork-card/artist-cartel/artist-cartel.component';
import {
  ArtistFilesComponent,
  GetArtistMemoryPipe,
} from 'src/app/pages/artists/artist-artwork-card/artist-files/artists-files.component';
import { ArtistArtworkListComponent } from 'src/app/pages/artists/artist-artwork-list/artist-artwork-list.component';
import { ArtistCardComponent } from 'src/app/pages/artists/artist-card/artist-card.component';
import { ArtistListComponent } from 'src/app/pages/artists/artist-list/artist-list.component';
import { ArtistsService } from 'src/app/pages/artists/artists.service';
import { CoreModule } from 'src/app/shared/components/core.module';
import { DynamicFormItemsModule } from 'src/app/shared/components/dynamic-form-items/dynamic-form-items.module';
import { EntityInfoModule } from 'src/app/shared/components/entity-info/entity-info.module';
import { NgZorroModule } from 'src/app/shared/components/ng-zorro.module';
import { PlatformFileModule } from 'src/app/shared/components/platform-files/platform-files.module';
import { LocalizeModule } from 'src/app/shared/localization/localize.module';
import { AuthService } from 'src/app/shared/services/auth.service';
import { createServiceResolver } from 'src/app/shared/utils/resolve-dataservice';

const canViewArtistList: CanActivateFn = (): Observable<boolean> => {
  const service = inject(ArtistsService);
  const auth = inject(AuthService);
  const router = inject(Router);
  return service.selectAll().pipe(
    withLatestFrom(auth.tokenPayload$),
    map(([artists, user]) => {
      const allowed = artists.length > 1 || (user?.isAdmin ?? false);
      if (!allowed) {
        void router.navigate([user?.artist ? `/artist/${user.artist}` : '/']);
      }
      return allowed;
    }),
  );
};

@NgModule({
  declarations: [
    ArtistCardComponent,
    ArtistArtworkListComponent,
    ArtistArtworkCardComponent,
    ArtistFilesComponent,
    GetArtistMemoryPipe,
    ArtistCartelComponent,
  ],
  imports: [
    CommonModule,
    CoreModule,
    FormsModule,
    ReactiveFormsModule,
    DynamicFormItemsModule,
    EntityInfoModule,
    LocalizeModule,
    NgZorroModule,
    PlatformFileModule,
    RouterModule.forChild([
      {
        path: '',
        component: ArtistCardComponent,
      },
      {
        path: 'artworks',
        component: ArtistArtworkListComponent,
      },
      {
        path: 'artworks/:contentID',
        component: ArtistArtworkCardComponent,
      },
    ]),
  ],
})
export class CurrentArtistModule {}

@NgModule({
  declarations: [ArtistListComponent],
  imports: [
    CommonModule,
    CoreModule,
    RouterModule.forChild([
      {
        path: '',
        component: ArtistListComponent,
        canActivate: [canViewArtistList],
      },
    ]),
  ],
  exports: [],
})
export class ArtistsModule {
  static get RouteInfo(): RouteInfo[] {
    return [
      {
        path: 'gallery',
        loadChildren: () =>
          import('./artists.module').then((m) => m.ArtistsModule),
        resolve: {
          artists: createServiceResolver(ArtistsService),
        },
        label: { FR: 'Galerie', EN: 'Gallery' },
        icon: 'file-image',
        visible: true,
        requiredAccess: RouteAccessLevel.Admin,
      },
      {
        path: 'artist/:id',
        sidebarPath: 'artist/current',
        sidebarActiveFilter: 'artist',
        reverseActiveFilter: 'artworks',
        loadChildren: () =>
          import('./artists.module').then((m) => m.CurrentArtistModule),
        resolve: {
          artists: createServiceResolver(ArtistsService),
        },
        label: { FR: 'Profil Artiste', EN: 'Artist Profile' },
        icon: 'file-image',
        visible: true,
        position: 'bottom',
        requiredAccess: (accesses: ApiTokenPayload): boolean =>
          !!accesses.artist,
      },
      {
        sidebarPath: 'artist/current/artworks',
        sidebarActiveFilter: 'artworks',
        label: { FR: 'Oeuvres', EN: 'Artworks' },
        icon: 'file-image',
        visible: true,
        resolve: {
          artists: createServiceResolver(ArtistsService),
        },
        position: 'bottom',
        requiredAccess: (accesses: ApiTokenPayload): boolean =>
          !!accesses.artist,
      },
    ];
  }
}
