import { Component, Input, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { LocalizedString } from '@apophenia/platform';
import { EditableFormItem } from 'src/app/shared/components/dynamic-form-items/_editable-form';
import { SelectOption } from 'src/app/shared/components/selector/selector.component';

@Component({
  selector: 'app-editable-selector-multi',
  templateUrl: './editable-selector-multi.component.html',
  styleUrls: ['./editable-selector-multi.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => EditableMultiSelectorComponent),
      multi: true,
    },
  ],
})
export class EditableMultiSelectorComponent<
  T = unknown,
> extends EditableFormItem<T[]> {
  @Input() options!: SelectOption<string>[];
  @Input() textStyle?: Partial<CSSStyleDeclaration>;

  currentLabel?: string | LocalizedString;

  override toggleEdit(edit?: boolean): void {
    super.toggleEdit(edit);
    if (!edit) {
      const stringValue = JSON.stringify(this.currentValue);
      this.currentLabel = this.options
        .filter((x) => stringValue.includes(JSON.stringify(x.value)))
        .map((x) => x.label)
        .join(', ');
    }
  }
}
