import { AmnesicContainerColumn, Memory } from '../amnesia';
import { LocalizedString } from '../localization/localized-string';
import { reverseRecord } from '../utils';

export enum ProjectTypes {
  VR = 'VR',
  WebAR = 'WebAR',
}

export type ProjectTemplate = VRProject | WebARProject;

export const ProjectPublicFileMap: Record<
  ProjectTypes,
  (val: number) => boolean
> = {
  VR: (val: VRProjectManifestType) => val == VRProjectManifestType.Thumbnail,
  WebAR: () => true,
};

export const filterFileType = (
  project: TemplateProject,
  type?: number,
): Memory[] => {
  return (project.manifests as Memory[]).filter((x) => x.groupID == type);
};

export const getThumbnail = (project: TemplateProject): Memory => {
  switch (project.template) {
    case ProjectTypes.VR:
      return (project.manifests as Memory[]).find((x) => x.isPublic);
    case ProjectTypes.WebAR:
      return (project.manifests as Memory[]).find(
        (x) => x.groupID == WebARProjectManifestType.Thumbnail,
      );
  }
};
// export const ProjectFileGroupMap: Record<
//   ProjectTypes,
//   (val: number) => string
// > = {
//   VR: (val: VRProjectManifestType) => val == VRProjectManifestType.Thumbnail,
//   WebAR: () => true,
// };

/*##############################################################################*/
/*##############################################################################*/

export enum VRProjectManifestType {
  Scene = 0,
  SoundBank = 1,
  Thumbnail = 2,
}

export const VRProjectFilesGroupsLookup = reverseRecord(VRProjectManifestType);

export interface VRProject extends Record<string, unknown> {
  videoPreview?: string;
  artists: string[];
  curators: string[];
  creationDate: Date;
  description: LocalizedString;
}

/*##############################################################################*/
/*##############################################################################*/
export enum WebARProjectManifestType {
  Thumbnail = 0,
  Text = -1,
  Image = 1,
  Video = 2,
  Model = 3,
}
export const WebARProjectFilesGroupsLookup = reverseRecord(
  WebARProjectManifestType,
);

export interface WebARProject extends Record<string, unknown> {
  description: LocalizedString;
  targets: Record<
    string,
    {
      artist: string;
      content: {
        fileID?: string;
        textContent?: string;
        anchor: string;
        type: WebARProjectManifestType;
      }[];
    }
  >;
}
/*##############################################################################*/
/*##############################################################################*/

export interface TemplateProject {
  manifests: AmnesicContainerColumn;
  metadata: ProjectTemplate;
  template: ProjectTypes;
}
