import { Component, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { LocalizedDict } from 'src/app/shared/localization/lozalize.models';

@Component({
  selector: 'app-yes-no-radio',
  templateUrl: './yes-no-radio.component.html',
  styleUrls: ['./yes-no-radio.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => YesNoRadioComponent),
      multi: true,
    },
  ],
})
export class YesNoRadioComponent implements ControlValueAccessor {
  translations: LocalizedDict = {
    yes: { EN: 'Yes', FR: 'Oui' },
    no: { EN: 'No', FR: 'Non' },
  };

  currentValue?: boolean;

  writeValue(value: boolean): void {
    this.currentValue = value;
  }
  registerOnChange(fn: (values: boolean) => void): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }
  onChange: (value: boolean) => void = () => {
    //
  };
  onTouched: () => void = () => {
    //
  };
}
