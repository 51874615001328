import { Directive, EventEmitter, Input, Output } from '@angular/core';
import { ControlValueAccessor } from '@angular/forms';
import { LocalizedString } from '@apophenia/platform';

@Directive()
export class EditableFormItem<T = unknown, TInput = T>
  implements ControlValueAccessor
{
  @Input() readonly = false;
  @Input() fieldTitle!: string | LocalizedString;
  @Input() currentValue!: T;
  @Output() currentValueChange = new EventEmitter<TInput>();

  isEditing = false;
  private valueBeforeEdit?: T;

  checkHasChanged(oldValue?: T, newValue?: T): boolean {
    return JSON.stringify(oldValue) != JSON.stringify(newValue);
  }

  toggleEdit(edit?: boolean): void {
    this.isEditing = edit ?? !this.isEditing;
    if (this.isEditing) {
      if (
        typeof this.currentValue == 'object' &&
        !(this.currentValue instanceof Date) &&
        this.currentValue !== undefined &&
        this.currentValue !== null
      ) {
        this.valueBeforeEdit = { ...this.currentValue };
      } else {
        this.valueBeforeEdit = this.currentValue;
      }
    } else if (
      !this.readonly &&
      this.checkHasChanged(this.valueBeforeEdit, this.currentValue)
    ) {
      const value = this.transformOutput(this.currentValue);
      this.onChange(value);
      this.currentValueChange.emit(value);
    }
  }

  writeValue(value: TInput): void {
    this.setAllValuesToCurrent(this.transformInput(value));
    this.toggleEdit(this.currentValue == undefined);
  }
  registerOnChange(fn: (values?: TInput) => void): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }
  onChange: (value?: TInput) => void = () => {
    //
  };
  onTouched: () => void = () => {
    //
  };

  protected transformInput(value: TInput): T {
    return value as unknown as T;
  }

  protected transformOutput(value?: T): TInput {
    return value as unknown as TInput;
  }

  private setAllValuesToCurrent(value: T): void {
    if (typeof value == 'object' && !(value instanceof Date) && !!value) {
      this.currentValue = { ...value };
      this.valueBeforeEdit = { ...value };
    } else {
      this.currentValue = value;
      this.valueBeforeEdit = value;
    }
  }
}
