//checks if the type is equal to or a child of another type
export type AssertIs<T, K> = [ArrayElementType<K>] extends [ArrayElementType<T>]
  ? true
  : never;

//extract element type from array
export type ArrayElementType<T> = T extends (infer TInfered)[] ? TInfered : T;

//union type of all keys of "T" that are of type "TOfType" (ex: KeyOfType<{name: string, type: number, comment: string}, string> -> "name" | "comment")
export type KeyOfType<T, TOfType> = keyof {
  [P in keyof T as AssertIs<T[P], TOfType> extends never ? never : P]: T[P];
} &
  string;

export type KeyArrayOf<TBase> = (keyof TBase)[];
