import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { HeaderComponent } from 'src/app/layout/default-layout/header/header.component';
import { SidebarComponent } from 'src/app/layout/default-layout/sidebar/sidebar.component';
import { CoreModule } from 'src/app/shared/components/core.module';
import { NgZorroModule } from 'src/app/shared/components/ng-zorro.module';
import { LocalizeModule } from 'src/app/shared/localization/localize.module';

@NgModule({
  declarations: [HeaderComponent, SidebarComponent],
  exports: [HeaderComponent, SidebarComponent],
  imports: [
    CommonModule,
    CoreModule,
    NgZorroModule,
    RouterModule,
    LocalizeModule,
  ],
})
export class DefaultLayoutModule {}
