import { inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivateFn,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { NotificationsService } from '../notifications/notifications.service';
import { MAIN_SPINNER_NAME } from 'src/app/app.component';
import { AuthService } from 'src/app/shared/services/auth.service';
import { RouteAccessLevel, getAllowedRoutes } from 'src/app/layout/routes';

export const AuthGuard: CanActivateFn = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,
): Observable<boolean> => {
  const spinner = inject(NgxSpinnerService);
  const authService = inject(AuthService);
  const notification = inject(NotificationsService);
  const router = inject(Router);
  void spinner.show(MAIN_SPINNER_NAME);
  return authService.authenticate$().pipe(
    map((accesses) => {
      void spinner.hide(MAIN_SPINNER_NAME);
      if (!accesses) {
        return false;
      }
      const allowedRoutes = getAllowedRoutes(accesses);
      if (state.url == '/' || state.url == '/login') {
        void router.navigate([`/${allowedRoutes[0].path}`]);
        return false;
      }
      const basePath = state.url.substring(1);
      const currentRoute = allowedRoutes.find((x) => basePath == x.path);
      if (accesses?.isAdmin || !currentRoute?.requiredAccess) {
        return true;
      }
      if (
        currentRoute.requiredAccess == RouteAccessLevel.Client &&
        ((accesses.organisms?.length ?? 0) > 0 ||
          (accesses.partners?.length ?? 0) > 0)
      ) {
        return true;
      }
      if (
        currentRoute.requiredAccess == RouteAccessLevel.Artist &&
        accesses.artist
      ) {
        return true;
      }

      if (!state.url.startsWith('/?')) {
        notification.error('This page requires an higher AccessLevel');
      }
      void router.navigate([`/${allowedRoutes[0].path}`]);
      return false;
    }),
  );
};
