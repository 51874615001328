import { Memory } from './memory/memory.entity';

export type AmnesicContainerColumn = string | Memory | Memory[]; //string | Memory | Memory[];

export type AmnesicContainerEntity<T> = {
  [k in keyof T]: T[k] extends Array<Memory>
    ? AmnesicContainerColumn
    : T[k] extends object
    ? AmnesicContainerEntity<T[k]>
    : T[k] extends Array<infer _T>
    ? AmnesicContainerEntity<_T>
    : T[k];
};
