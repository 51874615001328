<div class="form-control">
  <div class="label-with-select">
    <span class="label"> {{ fieldTitle | localize }} </span>
    <nz-radio-group [(ngModel)]="currentLocal">
      <label nz-radio-button [nzValue]="'EN'"> EN </label>
      <label nz-radio-button [nzValue]="'FR'"> FR </label>
    </nz-radio-group>
  </div>
  <div class="field-with-edit">
    <div class="text-container" *ngIf="!isEditing">
      <span class="value" [style]="textStyle">{{
        currentValue[currentLocal] ?? '-'
      }}</span>
    </div>
    <div class="text-container" *ngIf="isEditing">
      <input
        nz-input
        *ngIf="format === 'input'"
        [style]="editingStyle ?? textStyle"
        [(ngModel)]="currentValue[currentLocal]"
      />
      <textarea
        *ngIf="!format || format === 'textarea'"
        nz-input
        [(ngModel)]="currentValue[currentLocal]"
        name="description"
        type="text"
        [rows]="defaultRows"
      ></textarea>
    </div>
    <span
      *ngIf="!readonly"
      class="field-edit-icon"
      nz-icon
      nzType="edit"
      nzTheme="outline"
      (click)="toggleEdit()"
    ></span>
  </div>
</div>
