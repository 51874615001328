<div class="form-control">
  <span class="label">{{ fieldTitle | localize }}</span>
  <div class="field-with-edit">
    <span class="value" *ngIf="!isEditing" [style]="textStyle">
      {{ (currentLabel | localize) ?? 'None' }}
    </span>

    <nz-select *ngIf="isEditing" [(ngModel)]="currentValue">
      <nz-option
        *ngFor="let opt of options"
        [nzValue]="opt.value"
        [nzLabel]="(opt.label | localize) ?? ''"
      >
      </nz-option>
    </nz-select>
    <span
      *ngIf="!readonly"
      class="field-edit-icon"
      nz-icon
      nzType="edit"
      nzTheme="outline"
      (click)="toggleEdit()"
    ></span>
  </div>
</div>
