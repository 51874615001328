import { CoreEntity, DeviceTypes } from '../../core-entity';

export interface Memory extends CoreEntity {
  version: number;
  lastUpdatedAt: Date;
  groupID: number;
  group?: string;
  device: DeviceTypes;
  fileName: string;
  fileSize: number;
  fileType: string;
  locale?: string;
  objectKey?: string;
  isUpdating: boolean;
  context: unknown;
  contextID: string;
  publicURI?: string;
  isPublic?: boolean;
}
