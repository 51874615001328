import { ArtistExpositionGroups } from '..';
import { MemoryEncodingRequestDTO } from '../../amnesia';

export type EncodeArtistMemoryDTO = Omit<
  MemoryEncodingRequestDTO,
  'isPublic' | 'device' | 'groupID'
>;

export interface EncodeArtistExpositionMemoryDTO
  extends Omit<MemoryEncodingRequestDTO, 'isPublic' | 'device' | 'groupID'> {
  contentID?: string;
  group: ArtistExpositionGroups;
}
