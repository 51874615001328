import { Injectable } from '@angular/core';
import {
  APIAccesses,
  User,
  UserAccesses,
  UserMetadata,
} from '@apophenia/platform';
import { MAIN_SPINNER_NAME } from 'src/app/app.component';
import {
  CommonDataService,
  DataState,
} from 'src/app/shared/services/_base-data.service';
import { UpdateUserMetadataDTO } from 'src/app/shared/services/current-users.service';

@Injectable({ providedIn: 'root' })
export class UsersService extends CommonDataService<User> {
  protected get baseURL(): string {
    return '/users';
  }
  async updateMetadata(
    userID: string,
    dto: UpdateUserMetadataDTO,
  ): Promise<void> {
    const metadata = await this.apiService.patch<UserMetadata>(
      `/users/${userID == 'self' ? '' : userID + '/metadata'}`,
      dto,
      MAIN_SPINNER_NAME,
    );
    this.updateOneEntity(userID, { metadata });
  }

  override async reloadData(): Promise<void> {
    if (!this.authService.tokenPayload$.value?.isAdmin) {
      const user = await this.apiService.get<User>(
        `${this.baseURL}/self`,
        MAIN_SPINNER_NAME,
      );
      if (!user) {
        return;
      }
      const newState: DataState<User, User> = {
        ids: [user.id],
        entities: {
          [user.id]: user,
        },
        raw: { [user.id]: user },
      };
      this.state$.next(newState);
      this.loaded = true;
    } else {
      await super.reloadData();
    }
  }
  async updateAccess(
    userID: string,
    dto: Partial<UserAccesses | APIAccesses>,
  ): Promise<void> {
    const newAccess = await this.apiService.patch<APIAccesses>(
      `${this.baseURL}/access`,
      { ...dto, userID },
    );
    this.updateOneEntity(userID, { accesses: newAccess });
  }
}
