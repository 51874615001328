import { LocalizedString } from '../../localization';

export interface CreateArtistDTO {
  userID?: string;

  name?: string;

  authenticID?: string;

  visible?: boolean;
  activeExposition?: string;

  bio?: LocalizedString;
  nationalities?: string;
  birthPlace?: string;
  birthYear?: number;
  birthMonth?: number;
  birthDay?: number;
}
