import { Injectable } from '@angular/core';
import { BehaviorSubject, map, Observable } from 'rxjs';
import {
  ApiTokenPayload,
  CredentialTokens,
  LoginDTO,
  PasswordResetDTO,
  UserMetadata,
} from '@apophenia/platform';
import { APIService } from 'src/app/shared/services/api.service';
import { MAIN_SPINNER_NAME } from 'src/app/app.component';

export type UpdateUserMetadataDTO = Partial<UserMetadata>;
export type CurrentUser = Omit<ApiTokenPayload, 'sub'> & {
  id: string;
  metadata?: UserMetadata;
};

@Injectable({ providedIn: 'root' })
export class CurrentUsersService {
  loaded = false;
  readonly currentUser$ = new BehaviorSubject<CurrentUser | undefined>(
    undefined,
  );

  constructor(private apiService: APIService) {}

  get artistID$(): Observable<string | undefined> {
    return this.currentUser$.pipe(map((u) => u?.artist));
  }
  get artistID(): string | undefined {
    return this.currentUser$.value?.artist;
  }
  get isAdmin$(): Observable<boolean> {
    return this.currentUser$.pipe(map((u) => u?.isAdmin ?? false));
  }
  get isAdmin(): boolean {
    return this.currentUser$.value?.isAdmin ?? false;
  }
  get userID(): string | undefined {
    return this.currentUser$.value?.id;
  }

  protected get baseURL(): string {
    return '/users/self';
  }

  changePassword(dto: PasswordResetDTO): Promise<void> {
    return this.apiService.patch<void>(
      `${this.baseURL}/password`,
      dto,
      MAIN_SPINNER_NAME,
    );
  }
  changeLogins(dto: LoginDTO): Promise<CredentialTokens> {
    return this.apiService.patch<CredentialTokens>(
      `${this.baseURL}/login`,
      dto,
      MAIN_SPINNER_NAME,
    );
  }
  async updateMetadata(dto: UpdateUserMetadataDTO): Promise<void> {
    const metadata = await this.apiService.patch<UserMetadata>(
      `${this.baseURL}`,
      dto,
      MAIN_SPINNER_NAME,
    );
    this.currentUser$.next({
      ...(this.currentUser$.value as CurrentUser),
      metadata,
    });
  }

  loadFromToken(payload: ApiTokenPayload): void {
    this.currentUser$.next({
      ...this.currentUser$.value,
      ...payload,
    });
  }

  async loadMetadata(): Promise<void> {
    this.loaded = false;
    const metadata = await this.apiService.get<UserMetadata>(`${this.baseURL}`);
    this.currentUser$.next({
      ...(this.currentUser$.value as CurrentUser),
      metadata,
    });
    this.loaded = true;
  }
}
