import { HttpClient, HttpEvent, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NzUploadFile } from 'ng-zorro-antd/upload';
import { Observable } from 'rxjs';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class FileUploadsService {
  currentProjectProgress$: BehaviorSubject<number>;
  currentUploads$: BehaviorSubject<string[]>;

  constructor(private http: HttpClient) {
    this.currentProjectProgress$ = new BehaviorSubject<number>(-1);
    this.currentUploads$ = new BehaviorSubject<string[]>([]);
  }

  uploadCompleted(uploadTag: string): void {
    this.currentUploads$.next(
      this.currentUploads$.value.filter((x) => x !== uploadTag),
    );
  }

  uploadFileAWSS3(
    uploadURL: string,
    file: NzUploadFile | File,
    fileType: string,
    uploading: string,
  ): Observable<HttpEvent<unknown>> {
    const headers = new HttpHeaders({
      'Content-Type': fileType,
    });
    this.currentUploads$.next([...this.currentUploads$.value, uploading]);
    return this.http.put<unknown>(uploadURL, file, {
      reportProgress: true,
      observe: 'events',
      headers,
    });
  }
}
