import { Component } from '@angular/core';
import { Observable, map } from 'rxjs';
import { FileUploadsService } from 'src/app/shared/services/file-uploads.service';

@Component({
  selector: 'app-upload-tracker',
  template: ` <div
    class="upload-tracker"
    *ngIf="progress | async; let progress"
  >
    <div class="tracker-bar" [style]="{ width: progress }"></div>
  </div>`,
  styleUrls: [`./upload-tracker.component.scss`],
})
export class UploadTrackerComponent {
  progress: Observable<string | undefined>;
  constructor(private fileUpload: FileUploadsService) {
    this.progress = this.fileUpload.currentProjectProgress$.pipe(
      map((x) => (x >= 0 ? `${x * 100}%` : undefined)),
    );
  }
}
