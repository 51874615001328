import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { UpdateExpositionContentDTO } from '@apophenia/platform';
import { Observable, tap } from 'rxjs';
import { ExpositionContentViewModel } from 'src/app/pages/artists/artists.models';
import { ArtistsService } from 'src/app/pages/artists/artists.service';
import { LocalizedDict } from 'src/app/shared/localization/lozalize.models';
import { yesNoChoices } from 'src/app/shared/models/translations.models';
import {
  EntityFormControls,
  NotNullControl,
} from 'src/app/shared/utils/form-controls';

type CartelFormControls = EntityFormControls<
  Pick<
    UpdateExpositionContentDTO,
    'hasCartel' | 'cartelPosition' | 'cartelRotation'
  > & {
    cartelWidth?: number;
    cartelHeight?: number;
    width?: number;
    height?: number;
    depth?: number;
  }
>;

@Component({
  selector: 'app-artist-cartel',
  templateUrl: './artist-cartel.component.html',
  styleUrls: ['./artist-cartel.component.scss'],
})
export class ArtistCartelComponent implements OnInit {
  @Input() artistID!: string;
  @Input() expoID!: string;
  @Input() contentID!: string;
  @Input() artistName!: string;
  yesNoChoices = yesNoChoices;

  cartelItem!: ElementRef<HTMLElement>;

  content$!: Observable<ExpositionContentViewModel>;

  formGroup!: FormGroup<CartelFormControls>;

  translations: LocalizedDict = {
    hasCartel: {
      EN: "Use a cartel to show the artwork's information in the exposition?",
      FR: "Utiliser un cartel pour afficher l'information de l'oeuvre dans l'exposition?",
    },
    submit: {
      EN: 'Save',
      FR: 'Sauvegarder',
    },
    width: {
      EN: 'Width',
      FR: 'Largeur',
    },
    height: {
      EN: 'Height',
      FR: 'Hauteur',
    },
  };

  constructor(
    private artistService: ArtistsService,
    private formBuilder: FormBuilder,
  ) {
    this.createFormGroup();
  }

  @ViewChild('cartelItem', { static: false }) set ft(
    cartel: ElementRef<HTMLElement>,
  ) {
    if (cartel != undefined) {
      this.cartelItem = cartel;
      this.updatePreview();
    }
  }

  async updateServer(): Promise<void> {
    const values = this.formGroup.value;
    await this.artistService.createOrUpdateContent(this.artistID, this.expoID, {
      id: this.contentID,
      hasCartel: values.hasCartel,
      cartelScale:
        values.cartelHeight && values.cartelWidth
          ? `${values.cartelWidth},${values.cartelHeight}`
          : undefined,
    });
  }

  updatePreview(): void {
    this.cartelItem.nativeElement.style.width = `${
      (this.formGroup.controls.cartelWidth.value ?? 0.5) * 1000
    }px`;
    this.cartelItem.nativeElement.style.height = `${
      (this.formGroup.controls.cartelHeight.value ?? 0.5) * 1000
    }px`;
  }

  ngOnInit(): void {
    this.content$ = this.artistService
      .selectContent(this.artistID, this.expoID, this.contentID)
      .pipe(
        tap((c) => {
          const contentSize = c.viewerScale?.split(',') ?? [];
          const cartelSize = c.cartelScale?.split(',') ?? [];
          this.formGroup.setValue({
            hasCartel: c.hasCartel,
            cartelPosition: c.cartelPosition,
            cartelRotation: c.cartelRotation,
            cartelWidth: cartelSize[0] ? parseFloat(cartelSize[0]) : 0.5,
            cartelHeight: cartelSize[1] ? parseFloat(cartelSize[1]) : 0.5,
            width: contentSize[0] ? parseFloat(contentSize[0]) : 0,
            height: contentSize[1] ? parseFloat(contentSize[1]) : 0,
            depth: contentSize[2] ? parseFloat(contentSize[2]) : 0,
          });
        }),
      );
  }

  private createFormGroup(): void {
    this.formGroup = this.formBuilder.group<CartelFormControls>({
      hasCartel: NotNullControl(false),
      cartelPosition: NotNullControl<string | undefined>(undefined),
      cartelRotation: NotNullControl<string | undefined>(undefined),
      cartelWidth: NotNullControl<number | undefined>(undefined),
      cartelHeight: NotNullControl<number | undefined>(undefined),
      width: NotNullControl<number | undefined>(undefined),
      height: NotNullControl<number | undefined>(undefined),
      depth: NotNullControl<number | undefined>(undefined),
    });
  }
}
