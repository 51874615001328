export const getEnvString = (key: string): string => {
  return process.env[key];
};
export const getEnvInt = (key: string, defaultValue: number): number => {
  const value = process.env[key];
  return value ? parseInt(value) : defaultValue;
};
export const getEnvFloat = (key: string, defaultValue: number): number => {
  const value = process.env[key];
  return value ? parseFloat(value) : defaultValue;
};
export const getEnvArray = (key: string, separator = ','): string[] => {
  return process.env[key]?.split(separator) ?? [];
};
export const getEnvBoolean = (key: string): boolean => {
  return process.env[key] == 'true';
};
