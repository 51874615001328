<div class="col">
  <app-action-bar>
    <app-selector
      [defaultSelection]="(artist$ | async)?.activeExposition"
      [options]="expositionChoices$ | async"
      (selectChanged)="selectedExposition$.next($event)"
    ></app-selector>
  </app-action-bar>

  <div class="row row-wrap">
    <nz-collapse>
      <ng-container *ngFor="let type of contentTypes">
        <nz-collapse-panel
          *ngIf="((selectedArtworks$ | async)?.[type]?.length ??0) > 0"
          [nzActive]="true"
          [nzHeader]="translations[type] | localize"
        >
          <button
            *ngFor="let artwork of (selectedArtworks$ | async)?.[type]"
            class="card artist-card"
            [style.background-color]="artwork.color"
            [routerLink]="artwork.url"
          >
            <div class="card-img">
              <img [src]="artwork.img" />
            </div>
            <span class="title" [innerHTML]="artwork.name"> </span>
          </button>
        </nz-collapse-panel>
      </ng-container>
    </nz-collapse>
  </div>
</div>
