import {
  ValidationArguments,
  ValidatorConstraint,
  ValidatorConstraintInterface,
} from 'class-validator';

export type ManifestGroupMap<T extends number> = T extends never
  ? never
  : Record<number, string>;

//type MetadataSignature = [Record<string, unknown>, number];

// export interface EntityMetadata<
//   T extends MetadataSignature = MetadataSignature,
// > {
//   groups?: ManifestGroupMap<T[1]>;
//   info?: T[0];
// }

// export interface EntityMetadataViewModel<
//   T extends MetadataSignature = MetadataSignature,
// > extends EntityMetadata<MetadataSignature> {
//   files?: Record<T[1], Memory>;
// }

@ValidatorConstraint()
export class IsRecord implements ValidatorConstraintInterface {
  validate(value: unknown): boolean {
    if (!value || typeof value == 'object') {
      return true;
    }
    return false;
  }

  defaultMessage(arg?: ValidationArguments): string {
    return `${arg.property} must be a Record<string,unknown> object`;
  }
}
