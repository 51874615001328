import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { RouteAccessLevel, RouteInfo } from 'src/app/layout/routes';
import { OrganismsService } from 'src/app/pages/organisms/organisms.service';
import { ProjectsCardComponent } from 'src/app/pages/projects/project-card/projects-card.component';
import { VRProjectsCardComponent } from 'src/app/pages/projects/project-card/templates/vr-project/vr-projects-card.component';
import { ProjectsListComponent } from 'src/app/pages/projects/project-list/projects-list.component';
import { ProjectsService } from 'src/app/pages/projects/projects.service';
import { CoreModule } from 'src/app/shared/components/core.module';
import { DynamicFormItemsModule } from 'src/app/shared/components/dynamic-form-items/dynamic-form-items.module';
import { EntityInfoModule } from 'src/app/shared/components/entity-info/entity-info.module';
import { PlatformFileModule } from 'src/app/shared/components/platform-files/platform-files.module';
import { LocalizeModule } from 'src/app/shared/localization/localize.module';
import { createServiceResolver } from 'src/app/shared/utils/resolve-dataservice';

@NgModule({
  declarations: [
    ProjectsCardComponent,
    ProjectsListComponent,
    VRProjectsCardComponent,
  ],
  imports: [
    CommonModule,
    CoreModule,
    FormsModule,
    ReactiveFormsModule,
    DynamicFormItemsModule,
    EntityInfoModule,
    LocalizeModule,
    PlatformFileModule,
    RouterModule.forChild([
      { path: '', component: ProjectsListComponent },
      { path: ':id', component: ProjectsCardComponent },
    ]),
  ],
})
export class ProjectsModule {
  static get RouteInfo(): RouteInfo[] {
    return [
      {
        path: 'projects',
        loadChildren: () =>
          import('./projects.module').then((m) => m.ProjectsModule),
        resolve: {
          organisms: createServiceResolver(OrganismsService),
          projects: createServiceResolver(ProjectsService),
        },
        label: { FR: 'Projets', EN: 'Projects' },
        icon: 'video-camera',
        visible: true,
        requiredAccess: RouteAccessLevel.Client,
      },
    ];
  }
}
