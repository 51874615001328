export interface UserInvitation {
  invitationCode: string;
  createAt: Date;
  isAdmin: boolean;
  addStorageContainer: boolean;
  partners?: string[];
  organisms?: string[];
  given_name: string;
  family_name: string;
  artistID?: string;
  authenticID?: number;
}
