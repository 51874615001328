import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

export enum NotificationType {
  Success = 'primary',
  Error = 'info',
}

@Injectable({
  providedIn: 'root',
})
export class NotificationsService {
  constructor(private toastr: ToastrService) {}

  error(message: string, title = 'Error'): void {
    this.toastr.error(message, title, {
      closeButton: true,
      enableHtml: true,
      positionClass: 'toast-top-center',
    });
  }

  success(message: string, title = 'Success'): void {
    this.toastr.info(message, title, {
      closeButton: true,
      enableHtml: true,
      positionClass: 'toast-top-center',
    });
  }
}
