import { Component } from '@angular/core';
import { Memory } from '@apophenia/platform';
import { Observable, map } from 'rxjs';
import { ArtistsService } from 'src/app/pages/artists/artists.service';
import { MISSING_PICTURE_IMAGE } from 'src/app/shared/models/assets.models';
import { ListItemViewModel } from 'src/app/shared/models/list-items.models';

@Component({
  selector: 'app-artists-list',
  templateUrl: './artist-list.component.html',
  styleUrls: ['./artist-list.component.scss'],
})
export class ArtistListComponent {
  artists$: Observable<ListItemViewModel[]>;

  constructor(private artistService: ArtistsService) {
    this.artists$ = this.artistService.selectAll().pipe(
      map((artists) =>
        artists.map((x) => ({
          id: x.id,
          name: x.name,
          img: (x.avatar as Memory)?.publicURI ?? MISSING_PICTURE_IMAGE,
          url: `/artist/${x.id}`,
        })),
      ),
    );
  }
}
