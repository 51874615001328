import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TooltipComponent } from './tooltip.component';
import { NgZorroModule } from 'src/app/shared/components/ng-zorro.module';

@NgModule({
  declarations: [TooltipComponent],
  imports: [CommonModule, NgZorroModule],
  exports: [TooltipComponent],
})
export class TooltipModule {}
