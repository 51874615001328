<div class="info-page-container">
  <div class="info-container">
    <div class="form-control">
      <span class="label">{{ translations['version'] | localize }}</span>
      <span class="value">{{ memory?.version ?? '-' }}</span>
    </div>
    <div class="form-control">
      <span class="label">{{ translations['lastUpdatedAt'] | localize }}</span>
      <span class="value">{{ (memory?.lastUpdatedAt | date) ?? '-' }}</span>
    </div>
    <div class="form-control">
      <span class="label">{{ translations['fileSize'] | localize }}</span>
      <span class="value"
        >{{
          memory?.fileSize ? (memory!.fileSize * 1e-6 | number : '0.0-2') : '-'
        }}Mb</span
      >
    </div>
    <div class="form-control">
      <span class="label">{{ translations['fileType'] | localize }}</span>
      <span class="value">{{
        (translations[memory?.fileType!] | localize) ?? '-'
      }}</span>
    </div>
    <div class="form-control">
      <span class="label">{{ translations['objectKey'] | localize }}</span>
      <button
        class="primary key-button"
        ngxClipboard
        [cbContent]="memory?.objectKey"
      >
        {{ translations['copyKey'] | localize }}
      </button>
    </div>
    <div class="form-control" *ngIf="memory?.publicURI">
      <span class="label">{{ translations['publicURI'] | localize }}</span>
      <button
        class="primary key-button"
        ngxClipboard
        [cbContent]="memory?.publicURI"
      >
        {{ translations['copyUrl'] | localize }}
      </button>
    </div>
    <ng-container *ngIf="isAdmin">
      <div class="form-control">
        <span class="label">{{ translations['isUpdating'] | localize }}</span>
        <span class="value">{{ memory?.isUpdating ? 'No' : 'Yes' }}</span>
      </div>
      <div class="form-control">
        <span class="label">{{ translations['contextID'] | localize }}</span>
        <span class="value">{{ memory?.contextID ?? '-' }}</span>
      </div>
    </ng-container>
  </div>
  <app-image-card
    class="img-container"
    *ngIf="showPreview"
    [img]="memory?.publicURI"
    (fileChanged)="uploadFile([$event])"
  ></app-image-card>
  <div
    *ngIf="!showPreview"
    appDragAndDropImage
    class="upload-container"
    (files)="uploadFile($event)"
  >
    <h3>{{ translations['update'] | localize }}</h3>
    <app-upload-button
      (fileEvent)="uploadFile($event.files)"
      [iconContent]="'cloud-upload'"
    >
    </app-upload-button>
  </div>
</div>
