<div class="col">
  <app-action-bar> </app-action-bar>
  <div class="row row-wrap">
    <button
      *ngFor="let m of modules$ | async"
      class="card modules-card"
      [routerLink]="m.url"
    >
      <div class="card-img">
        <img [src]="m.img" />
      </div>
      <div class="title">
        {{ m.name }}
      </div>
    </button>
  </div>
</div>
