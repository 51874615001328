import { ChangeDetectorRef, Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Observable, switchMap, tap } from 'rxjs';
import {
  EntityFormControls,
  EntityFormGroup,
  NotNullControl,
} from 'src/app/shared/utils/form-controls';
import { CurrentUsersService } from 'src/app/shared/services/current-users.service';
import {
  CreateProjectDTO,
  LocalizedString,
  ProjectCategories,
  ProjectTemplate,
  ProjectTypes,
  VRProject,
  WebARProject,
} from '@apophenia/platform';
import { ProjectsService } from 'src/app/pages/projects/projects.service';
import { ProjectViewModel } from 'src/app/pages/projects/projects.models';
import { ActivatedRoute, Router } from '@angular/router';
import { LocalizedDict } from 'src/app/shared/localization/lozalize.models';
import { VR_PROJECT_TABS } from 'src/app/pages/projects/project-card/templates/vr-project/vr-projects-card.component';
import { TabsModuleConfigs } from 'src/app/shared/components/multiple-tabs/multiple-tabs.component';

type ProjectFormControls = EntityFormControls<
  CreateProjectDTO & {
    id?: string;
  },
  'metadata'
>;

@Component({
  selector: 'app-projects-card',
  templateUrl: './projects-card.component.html',
  styleUrls: ['./projects-card.component.scss'],
})
export class ProjectsCardComponent {
  projectTabsConfigs?: TabsModuleConfigs = undefined;
  currentTab = 0;

  formGroup?: FormGroup<ProjectFormControls>;
  isAdmin$: Observable<boolean>;

  selectedProject$: Observable<ProjectViewModel | undefined>;
  currentType = ProjectTypes.VR;

  translations: LocalizedDict = {
    createdAt: { EN: 'Creation date:', FR: 'Date de création' },
    video: { EN: 'Video preview URL:', FR: 'Lien vidéo de présentation:' },
  };

  constructor(
    private currentUserService: CurrentUsersService,
    private projectService: ProjectsService,
    private route: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder,
    private cdr: ChangeDetectorRef,
  ) {
    this.isAdmin$ = this.currentUserService.isAdmin$;
    this.selectedProject$ = this.route.params.pipe(
      switchMap((params) =>
        this.projectService.selectById(params.id as string),
      ),
      tap((project) => {
        this.currentType = project?.template ?? ProjectTypes.VR;
        if (this.formGroup?.value?.id != project?.id) {
          this.updateTemplate(project);
        }
      }),
    );
  }

  updateTemplate(project: ProjectViewModel): void {
    switch (project.template) {
      case ProjectTypes.VR:
        this.projectTabsConfigs = VR_PROJECT_TABS;
        break;
      case ProjectTypes.WebAR:
        break;
    }
  }

  navigateToProject(project?: ProjectViewModel): void {
    if (!project) {
      void this.router.navigate(['/projects']);
    } else {
      void this.router.navigate([`/projects/${project.id}`], {});
    }
  }

  // updateFormGroup(proj?: ProjectViewModel): void {
  //   const metadata = this.setMetadataGroup(proj?.template, proj?.metadata);
  //   this.formGroup?.setValue({
  //     id: proj?.id as string,
  //     organismID: proj?.organismID as string,
  //     name: proj?.name as string,
  //     metadata,
  //     isFree: proj?.isFree ?? false,
  //     isDev: proj?.isDev ?? false,
  //     isPrivate: proj?.isPrivate ?? false,
  //     platformVersion: proj?.platformVersion ?? '0.0.0',
  //     categories: proj?.categories ?? [],
  //   });
  // }

  // createFormGroup(): void {
  //   this.formGroup = this.formBuilder.group<ProjectFormControls>({
  //     id: NotNullControl('', [Validators.required]),
  //     organismID: NotNullControl('', [Validators.required]),
  //     name: NotNullControl('', [Validators.required]),
  //     metadata: this.formBuilder.group({}),
  //     isFree: NotNullControl(true),
  //     isDev: NotNullControl(false),
  //     isPrivate: NotNullControl(true),
  //     platformVersion: NotNullControl('0.0.0', [Validators.required]),
  //     categories: NotNullControl<ProjectCategories[]>([]),
  //   });
  // }

  // setMetadataGroup(
  //   type?: ProjectTypes,
  //   metadata?: ProjectTemplate,
  // ): Record<string, unknown> {
  //   if (!metadata || !type) {
  //     return {};
  //   }
  //   let group: EntityFormGroup<ProjectTemplate>;
  //   let values: Record<string, unknown>;
  //   switch (type) {
  //     case ProjectTypes.VR:
  //       group = this.formBuilder.group<EntityFormControls<VRProject>>({
  //         videoPreview: NotNullControl(''),
  //         artists: NotNullControl<string[]>([]),
  //         curators: NotNullControl<string[]>([]),
  //         creationDate: NotNullControl<Date>(new Date(), [Validators.required]),
  //         description: NotNullControl<LocalizedString>({}),
  //       });
  //       values = {
  //         videoPreview: metadata.videoPreview,
  //         artists: metadata.artists ?? [],
  //         curators: metadata.curators ?? [],
  //         creationDate: metadata.creationDate ?? new Date(),
  //         description: metadata.description ?? {},
  //       };
  //       break;
  //     case ProjectTypes.WebAR:
  //       group = this.formBuilder.group<EntityFormControls<WebARProject>>({
  //         artists: NotNullControl<string[]>([]),
  //         description: NotNullControl<LocalizedString>({}),
  //       });
  //       values = {
  //         description: metadata.description ?? {},
  //         targets: [],
  //       };
  //       break;
  //   }

  //   this.formGroup?.setControl('metadata', group);
  //   return values;
  // }
}
