<div class="col">
  <app-action-bar> </app-action-bar>
  <div class="row row-wrap">
    <button
      *ngFor="let artist of artists$ | async"
      class="card artist-card"
      [routerLink]="artist.url"
    >
      <div class="card-img">
        <img [src]="artist.img" />
      </div>
      <div class="title">
        {{ artist.name }}
      </div>
    </button>
  </div>
</div>
