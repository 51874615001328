import { Component } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
//import { ApiTokenPayload } from '@apophenia/shared/dist/pareidolia';
import { Observable, filter, map, share, withLatestFrom } from 'rxjs';
import { AllowedRoute, getAllowedRoutes } from 'src/app/layout/routes';
import { CurrentUsersService } from 'src/app/shared/services/current-users.service';

type ActivatableNavItem = AllowedRoute & {
  activeClass: string;
};

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent {
  topNav$: Observable<ActivatableNavItem[]>;
  bottomNav$: Observable<ActivatableNavItem[]>;

  private sidebarNav$?: Observable<ActivatableNavItem[]>;

  constructor(
    private userService: CurrentUsersService,
    private router: Router,
  ) {
    this.sidebarNav$ = this.router.events.pipe(
      map((x) => (x instanceof NavigationEnd ? x : undefined)),
      filter((x) => !!x),
      withLatestFrom(this.userService.currentUser$),
      map(([event, u]) => {
        if (!u) {
          return [];
        }
        return getAllowedRoutes(u)
          .filter((x) => x.visible)
          .map((x) => {
            let activeClass = '';
            if (
              event?.url.includes(x.path) ||
              ((!x.sidebarActiveFilter ||
                event?.url.includes(x.sidebarActiveFilter)) &&
                (!x.reverseActiveFilter ||
                  !event?.url.includes(x.reverseActiveFilter)))
            ) {
              activeClass = 'active-route';
            }
            return {
              ...x,
              activeClass,
            };
          });
      }),
      share(),
    );
    this.topNav$ = this.sidebarNav$.pipe(
      map((items) => items.filter((x) => !x.position || x.position == 'top')),
    );
    this.bottomNav$ = this.sidebarNav$.pipe(
      map((items) => items.filter((x) => x.position == 'bottom')),
    );
  }
}
