import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CoreModule } from 'src/app/shared/components/core.module';
import { EditableDateComponent } from 'src/app/shared/components/dynamic-form-items/editable-date/editable-date.component';
import { EditableLocalizedTextComponent } from 'src/app/shared/components/dynamic-form-items/editable-localized-text/editable-localized-text.component';
import { EditableNumberComponent } from 'src/app/shared/components/dynamic-form-items/editable-number/editable-number.component';
import { EditableRadioComponent } from 'src/app/shared/components/dynamic-form-items/editable-radio/editable-radio.component';
import { EditableMultiSelectorComponent } from 'src/app/shared/components/dynamic-form-items/editable-selector-multi/editable-selector-multi.component';
import { EditableSelectorComponent } from 'src/app/shared/components/dynamic-form-items/editable-selector/editable-selector.component';
import { EditableTextComponent } from 'src/app/shared/components/dynamic-form-items/editable-text/editable-text.component';
import { EditableVectorComponent } from 'src/app/shared/components/dynamic-form-items/editable-vector/editable-vector.component';
import { NgZorroModule } from 'src/app/shared/components/ng-zorro.module';
import { LocalizeModule } from 'src/app/shared/localization/localize.module';

const items = [
  EditableTextComponent,
  EditableDateComponent,
  EditableLocalizedTextComponent,
  EditableSelectorComponent,
  EditableMultiSelectorComponent,
  EditableRadioComponent,
  EditableNumberComponent,
  EditableVectorComponent,
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    CoreModule,
    NgZorroModule,
    ReactiveFormsModule,
    LocalizeModule,
  ],
  declarations: items,
  exports: items,
})
export class DynamicFormItemsModule {}
