import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { RouteAccessLevel, RouteInfo } from 'src/app/layout/routes';
import { OrganismCardComponent } from 'src/app/pages/organisms/organism-card/organisms-card.component';
import { OrganismListComponent } from 'src/app/pages/organisms/organism-list/organisms-list.component';
import { OrganismSelectorComponent } from 'src/app/pages/organisms/organism-selector/organisms-selector.component';
import { OrganismsService } from 'src/app/pages/organisms/organisms.service';
import { CoreModule } from 'src/app/shared/components/core.module';
import { DynamicFormItemsModule } from 'src/app/shared/components/dynamic-form-items/dynamic-form-items.module';
import { EntityInfoModule } from 'src/app/shared/components/entity-info/entity-info.module';
import { PlatformFileModule } from 'src/app/shared/components/platform-files/platform-files.module';
import { LocalizeModule } from 'src/app/shared/localization/localize.module';
import { createServiceResolver } from 'src/app/shared/utils/resolve-dataservice';

@NgModule({
  declarations: [
    OrganismCardComponent,
    OrganismListComponent,
    OrganismSelectorComponent,
  ],
  imports: [
    CommonModule,
    CoreModule,
    FormsModule,
    ReactiveFormsModule,
    DynamicFormItemsModule,
    LocalizeModule,
    PlatformFileModule,
    EntityInfoModule,
    RouterModule.forChild([
      {
        path: '',
        component: OrganismListComponent,
      },
      {
        path: ':id',
        component: OrganismCardComponent,
      },
    ]),
  ],
  exports: [OrganismSelectorComponent],
})
export class OrganismModule {
  static get RouteInfo(): RouteInfo[] {
    return [
      {
        path: 'organisms',
        loadChildren: () =>
          import('./organisms.module').then((m) => m.OrganismModule),
        resolve: {
          organisms: createServiceResolver(OrganismsService),
        },
        label: { EN: 'Organisms', FR: 'Organismes' },
        icon: 'branches',
        requiredAccess: RouteAccessLevel.Client,
        visible: true,
      },
    ];
  }
}
