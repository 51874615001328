import { Type, inject } from '@angular/core';
import { CommonDataService } from 'src/app/shared/services/_base-data.service';
import { Func } from 'src/app/shared/utils/functions';

export const createServiceResolver = (
  serviceToken: Type<CommonDataService<any, any>>,
): Func<boolean> => {
  return async (): Promise<boolean> => {
    const service = inject(serviceToken);
    if (!service.loaded) {
      await service.reloadData();
    }
    return service.loaded;
  };
};
