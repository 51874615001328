<div class="form-control">
  <span class="label">{{ fieldTitle | localize }}</span>
  <div class="field-with-edit">
    <span
      class="value"
      *ngIf="!isEditing"
      [style]="textStyle"
      [innerHTML]="currentValue"
    >
    </span>
    <input *ngIf="isEditing" [(ngModel)]="currentValue" />
    <span
      *ngIf="!readonly"
      class="field-edit-icon"
      nz-icon
      nzType="edit"
      nzTheme="outline"
      (click)="toggleEdit()"
    ></span>
  </div>
</div>
