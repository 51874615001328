import { Component } from '@angular/core';
import { Memory, Organism } from '@apophenia/platform';
import { Observable, map } from 'rxjs';
import { OrganismsService } from 'src/app/pages/organisms/organisms.service';
import { MISSING_PICTURE_IMAGE } from 'src/app/shared/models/assets.models';

export type OrganismListItem = Pick<Organism, 'id' | 'name'> & {
  img: string;
  url: string;
};

@Component({
  selector: 'app-organisms-list',
  templateUrl: './organisms-list.component.html',
  styleUrls: ['./organisms-list.component.scss'],
})
export class OrganismListComponent {
  organisms$: Observable<OrganismListItem[]>;

  constructor(private orgService: OrganismsService) {
    this.organisms$ = this.orgService.selectAll().pipe(
      map((orgs) =>
        orgs.map((x) => ({
          id: x.id,
          name: x.name,
          img: (x.thumbnail as Memory)?.publicURI ?? MISSING_PICTURE_IMAGE,
          url: `/organisms/${x.id}`,
        })),
      ),
    );
  }
}
