import {
  Directive,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  Output,
} from '@angular/core';

@Directive({
  selector: '[appDragAndDropImage]',
})
export class DragAndDropDirective {
  @Output() files: EventEmitter<File[]> = new EventEmitter<File[]>();

  @HostBinding('style.background')
  public backgroundColor = 'none';
  @Input()
  public inactiveBackgroundColor = 'none';
  @Input()
  public onHoverBackgroundColor = '#f3ff4b';
  @Input()
  public imagesOnly = true;

  @HostListener('dragover', ['$event'])
  public onDragOver(event: DragEvent): void {
    event.preventDefault();
    event.stopPropagation();
    this.backgroundColor = this.onHoverBackgroundColor;
  }

  @HostListener('dragleave', ['$event'])
  public onDragLeave(event: DragEvent): void {
    event.preventDefault();
    event.stopPropagation();
    this.backgroundColor = this.inactiveBackgroundColor;
  }

  @HostListener('drop', ['$event'])
  public onImageDrop(event: DragEvent): void {
    event.preventDefault();
    event.stopPropagation();
    this.backgroundColor = this.inactiveBackgroundColor;

    const files: File[] = [];
    if (event.dataTransfer == null) {
      return;
    }
    for (let i = 0; i < event.dataTransfer.files.length; i++) {
      const file = event.dataTransfer.files[i];
      files.push(file);
    }

    if (files.length > 0) {
      this.files.emit(files);
    }
  }
}
