import { Component } from '@angular/core';
import { Observable, combineLatest, map } from 'rxjs';

import { CurrentUsersService } from 'src/app/shared/services/current-users.service';
import { OrganismsService } from 'src/app/pages/organisms/organisms.service';

import { ProjectsService } from 'src/app/pages/projects/projects.service';
import { Project } from '@apophenia/platform';
import { MISSING_PICTURE_IMAGE } from 'src/app/shared/models/assets.models';

export type ProjectListItem = Pick<Project, 'id' | 'name'> & {
  img: string;
  url: string;
};

@Component({
  selector: 'app-projects-list',
  templateUrl: './projects-list.component.html',
  styleUrls: ['./projects-list.component.scss'],
})
export class ProjectsListComponent {
  isAdmin$: Observable<boolean>;
  organisms$: Observable<
    { id: string; name: string; projects: ProjectListItem[] }[]
  >;

  constructor(
    private currentUserService: CurrentUsersService,
    private organismService: OrganismsService,
    private projectService: ProjectsService,
  ) {
    this.isAdmin$ = this.currentUserService.isAdmin$;
    this.organisms$ = combineLatest([
      this.organismService.selectAll(),
      this.projectService.selectAll(),
    ]).pipe(
      map(([orgs, projects]) =>
        orgs.map((o) => ({
          id: o.id,
          name: o.name,
          projects: projects
            .filter((p) => p.organismID == o.id)
            .map((p) => ({
              id: p.id,
              name: p.name,
              img: p.thumbnail?.publicURI ?? MISSING_PICTURE_IMAGE,
              url: `/projects/${p.id}`,
            })),
        })),
      ),
    );
  }
}
