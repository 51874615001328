import { IsArray, IsBoolean, IsString, Validate } from 'class-validator';
import { IsRecord } from '../../entity-metadata';
import { ProjectTemplate } from '../templates';
import { MemoryEncodingRequestDTO } from '../../amnesia/dto/update-memory.dto';

export type CreateProjectManifestDTO = Omit<
  MemoryEncodingRequestDTO,
  'isPublic'
>;

export class CreateProjectDTO<TTemplate = ProjectTemplate> {
  @IsString()
  organismID: string;
  @Validate(IsRecord)
  metadata: TTemplate;
  @IsString()
  name: string;

  @IsString()
  platformVersion: string;
  @IsBoolean()
  isDev: boolean;
  @IsBoolean()
  isPrivate: boolean;
  @IsBoolean()
  isFree: boolean;
  @IsString({ each: true })
  @IsArray()
  categories: string[];
}
