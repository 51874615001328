import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Observable, map, tap, withLatestFrom } from 'rxjs';
import {
  EntityFormControls,
  NotNullControl,
} from 'src/app/shared/utils/form-controls';
import {
  CreateModuleMetadataDTO,
  GenericModuleType,
  LocalizedString,
  MemoryEncodingRequestDTO,
} from '@apophenia/platform';
import { TabsModuleConfigs } from 'src/app/shared/components/multiple-tabs/multiple-tabs.component';
import { ActivatedRoute, Router } from '@angular/router';
import { LocalizedDict } from 'src/app/shared/localization/lozalize.models';
import { GenericModulesService } from 'src/app/pages/generic-modules/generic-modules.service';
import { GenericModuleViewModel } from 'src/app/pages/generic-modules/generic-modules.models';
import { MemoryFileUpdateEvent } from 'src/app/shared/components/platform-files/platform-files-info/platform-files-info.component';
import { firstValuePromise } from 'src/app/shared/utils/promisify';

type ModulesFormControls = EntityFormControls<
  Partial<CreateModuleMetadataDTO> & {
    id?: string;
  }
>;

enum ModulesTabs {
  Information,
  Scene,
  Audio,
}

const MODULES_TABS: TabsModuleConfigs = [
  {
    label: 'Information',
  },
  {
    label: { EN: 'Scene', FR: 'Scène' },
  },
  {
    label: 'Audio',
    disabled: true,
  },
];

@Component({
  selector: 'app-modules-card',
  templateUrl: './modules-card.component.html',
  styleUrls: ['./modules-card.component.scss'],
})
export class GenericModulesCardComponent {
  ModulesTabs = ModulesTabs;
  modulesTabsConfigs = MODULES_TABS;
  currentTab: ModulesTabs = 0;

  formGroup?: FormGroup<ModulesFormControls>;

  selectedModule$: Observable<GenericModuleViewModel | undefined>;

  translations: LocalizedDict = {
    createdAt: { EN: 'Creation date:', FR: 'Date de création' },
  };

  constructor(
    private modulesService: GenericModulesService,
    private route: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder,
  ) {
    this.createFormGroup();
    this.selectedModule$ = this.route.params.pipe(
      withLatestFrom(this.modulesService.selectAll()),
      map(([params, modules]) => modules.find((m) => m.id == params.id)),
      tap((n) => {
        if (this.formGroup?.value?.id != n?.id) {
          this.updateFormGroup(n);
        }
      }),
    );
  }

  async updateServer(key: keyof ModulesFormControls): Promise<void> {
    const dto: Partial<CreateModuleMetadataDTO> = {
      [key]: this.formGroup?.controls[key].value,
    };
    await this.modulesService.updateOne(
      this.formGroup?.controls.id.value as string,
      dto,
    );
  }

  async uploadFile(file: MemoryFileUpdateEvent): Promise<void> {
    const params = await firstValuePromise(this.route.params);
    if (!params?.id) {
      return;
    }
    const dto: Partial<MemoryEncodingRequestDTO> = {
      id: file.memory?.id,
      device: file.memory?.device,
    };
    await this.modulesService.uploadFile(
      params.id as string,
      {
        fileUpload: { file: file.file },
        dto,
      },
      'manifests',
    );
  }

  navigateToModule(mod?: GenericModuleViewModel): void {
    if (!mod) {
      void this.router.navigate(['/modules']);
    } else {
      void this.router.navigate([`/modules/${mod.id}`], {});
    }
  }

  updateFormGroup(proj?: GenericModuleViewModel): void {
    this.formGroup?.setValue({
      id: proj?.id as string,
      name: proj?.name as string,
      description: proj?.description ?? {},
      isDev: proj?.isDev ?? false,
      type: proj?.type ?? GenericModuleType.Exposition,
      platformVersion: proj?.platformVersion ?? '0.0.0',
    });
  }

  createFormGroup(): void {
    this.formGroup = this.formBuilder.group<ModulesFormControls>({
      id: NotNullControl('', [Validators.required]),
      name: NotNullControl('', [Validators.required]),
      type: NotNullControl<GenericModuleType | undefined>(undefined),
      isDev: NotNullControl(false),
      description: NotNullControl<LocalizedString>({}),
      platformVersion: NotNullControl('0.0.0', [Validators.required]),
    });
  }
}
