import { Component } from '@angular/core';

export const MAIN_SPINNER_NAME = 'main-spinner';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'Apophenia';

  get spinnerName(): string {
    return MAIN_SPINNER_NAME;
  }
}
