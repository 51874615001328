import { Component, Input, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { EditableSelectorComponent } from 'src/app/shared/components/dynamic-form-items/editable-selector/editable-selector.component';
import { SelectOption } from 'src/app/shared/components/selector/selector.component';

@Component({
  selector: 'app-editable-radio',
  templateUrl: './editable-radio.component.html',
  styleUrls: ['./editable-radio.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => EditableRadioComponent),
      multi: true,
    },
  ],
})
export class EditableRadioComponent<
  T = unknown,
> extends EditableSelectorComponent<T> {
  @Input() options: SelectOption<T>[] = [];

  @Input() textStyle?: Partial<CSSStyleDeclaration>;
  @Input() editingStyle?: Partial<CSSStyleDeclaration>;
}
