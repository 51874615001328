import { MemoryLimits, MemoryTracking } from './context.limits';
import { Memory } from '../memory/memory.entity';
import { CoreEntity } from '../../core-entity';

export enum MemoryContextType {
  Unbound = 'Unbound',
  Module = 'Module',
  Client = 'Client',
  Artist = 'Artist',
}

export type MemoryGroups = Record<string & 'default', number>;

export interface MemoryContext extends CoreEntity {
  domain: string;
  allowPublicMemories?: boolean;
  apiKey?: string;
  limits: MemoryLimits;
  tracking: MemoryTracking;
  groups?: string;
  prefix?: string;
  memories: Memory[];
}
