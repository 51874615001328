import { Component } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import {
  ContentSupportType,
  CreateExpositionContentDTO,
  ExpositionContentType,
  LocalizedString,
} from '@apophenia/platform';
import { Observable, map, switchMap, tap, withLatestFrom } from 'rxjs';
import {
  ArtistExpositionViewModel,
  ArtistViewModel,
  ExpositionContentViewModel,
} from 'src/app/pages/artists/artists.models';
import { ArtistsService } from 'src/app/pages/artists/artists.service';
import { VectorAxis } from 'src/app/shared/components/dynamic-form-items/editable-vector/editable-vector.component';
import { TabsModuleConfigs } from 'src/app/shared/components/multiple-tabs/multiple-tabs.component';
import { LocalizedDict } from 'src/app/shared/localization/lozalize.models';
import { CurrentUsersService } from 'src/app/shared/services/current-users.service';
import {
  EntityFormControls,
  NotNullControl,
} from 'src/app/shared/utils/form-controls';
import { firstValuePromise } from 'src/app/shared/utils/promisify';

type ArtworkControls = EntityFormControls<
  Partial<
    Omit<
      CreateExpositionContentDTO,
      | 'tags'
      | 'sourceFile'
      | 'previewFile'
      | 'platformFile'
      | 'targetTokenURL'
      | 'locale'
    >
  > & {
    id?: string;
  }
>;

enum ArtTabs {
  Information,
  Files,
  Position,
  Cartel,
  Admin,
}

const ART_TABS: TabsModuleConfigs = {
  [ArtTabs.Information]: {
    label: 'Information',
  },
  [ArtTabs.Files]: {
    label: { EN: 'Files', FR: 'Fichiers' },
  },
  [ArtTabs.Position]: {
    label: 'Position',
    disabled: true,
  },
  [ArtTabs.Cartel]: {
    label: 'Cartel',
    disabled: true,
  },
  [ArtTabs.Admin]: {
    label: 'Admin',
    adminOnly: true,
  },
};

@Component({
  selector: 'app-artists-artwork-card',
  templateUrl: './artist-artwork-card.component.html',
  styleUrls: ['./artist-artwork-card.component.scss'],
})
export class ArtistArtworkCardComponent {
  ArtTabs = ArtTabs;
  translations: LocalizedDict = {
    creationDate: { EN: 'Creation date:', FR: 'Date de création' },
    price: { EN: 'Price (USD):', FR: 'Prix (USD):' },
    viewerScale: { EN: 'Dimensions', FR: 'Taille' },
  };

  dimensionsAxis: VectorAxis[] = [
    { axis: 'w', localized: { EN: 'width', FR: 'largeur' } },
    { axis: 'h', localized: { EN: 'height', FR: 'hauteur' } },
    { axis: 'd', localized: { EN: 'depth', FR: 'profondeur' } },
  ];

  tabsConfigs = ART_TABS;
  currentTab = 0;

  artworkTypes = ExpositionContentType;

  formGroup!: FormGroup<ArtworkControls>;
  artist$: Observable<ArtistViewModel>;
  selectedExposition$: Observable<ArtistExpositionViewModel | undefined>;
  selectedArtwork$: Observable<ExpositionContentViewModel | undefined>;

  constructor(
    private artistService: ArtistsService,
    private currentUser: CurrentUsersService,
    private route: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder,
  ) {
    this.createFormGroup();
    this.artist$ = this.route.params.pipe(
      switchMap((params) =>
        this.artistService.selectById(
          (params.id as string) ?? this.currentUser.artistID,
        ),
      ),
    );
    this.selectedExposition$ = this.artist$.pipe(
      withLatestFrom(this.route.params.pipe(map((p) => p.contentID as string))),
      map(([artist, contentID]) => {
        return artist.expositions.find((e) =>
          e.content.some((c) => c.id == contentID),
        );
      }),
    );
    this.selectedArtwork$ = this.selectedExposition$.pipe(
      withLatestFrom(this.route.params),
      map(([e, params]) => e?.content.find((x) => x.id == params.contentID)),
      tap((artwork) => {
        if (this.formGroup?.value?.id != artwork?.id) {
          this.updateFormGroup(artwork);
        }
        const hasFiles =
          !!artwork?.source?.publicURI && !!artwork?.preview?.publicURI;
        const tabs = this.tabsConfigs;
        tabs[ArtTabs.Position].disabled = !hasFiles;
        tabs[ArtTabs.Cartel].disabled = !hasFiles;
        this.tabsConfigs = { ...tabs };
      }),
    );
  }

  async navigateToArtworks(): Promise<void> {
    const artist = await firstValuePromise(this.artist$);
    void this.router.navigate([`/artist/${artist.id}/artworks`]);
  }

  async checkTabsValidity(): Promise<void> {
    const artwork = await firstValuePromise(this.selectedArtwork$);

    const hasFiles =
      !!artwork?.source?.publicURI && !!artwork?.preview?.publicURI;
    const tabs = this.tabsConfigs;
    tabs[ArtTabs.Position].disabled = !hasFiles;
    tabs[ArtTabs.Cartel].disabled = !hasFiles;
    this.tabsConfigs = { ...tabs };
  }

  async updateServer(key: keyof ArtworkControls): Promise<void> {
    if (!this.formGroup.controls.id.value) {
      return;
    }
    const expo = (await firstValuePromise(
      this.selectedExposition$,
    )) as ArtistExpositionViewModel;
    const dto: Partial<CreateExpositionContentDTO> & { id: string } = {
      id: this.formGroup.controls.id.value,
      [key]: this.formGroup.controls[key].value,
    };
    await this.artistService.createOrUpdateContent(expo.artistID, expo.id, dto);
  }

  private updateFormGroup(artwork?: ExpositionContentViewModel): void {
    this.formGroup.setValue({
      id: artwork?.id,
      name: artwork?.name,
      type: artwork?.type,

      creationDate: artwork?.creationDate,
      medium: artwork?.medium,
      priceUSD: artwork?.priceUSD,
      description: artwork?.description,

      supportType: artwork?.supportType,
      viewerPosition: artwork?.viewerPosition,
      viewerRotation: artwork?.viewerRotation,
      viewerScale: artwork?.viewerScale,

      hasCartel: artwork?.hasCartel,
      cartelScale: artwork?.cartelScale,
      cartelPosition: artwork?.cartelPosition,
      cartelRotation: artwork?.cartelRotation,
    });
  }

  private createFormGroup(): void {
    this.formGroup = this.formBuilder.group<ArtworkControls>({
      id: NotNullControl<string | undefined>(undefined),
      name: NotNullControl(''),
      type: NotNullControl<ExpositionContentType | undefined>(undefined),
      description: NotNullControl<LocalizedString>({}),
      creationDate: NotNullControl<Date | undefined>(undefined),
      medium: NotNullControl<string | undefined>(undefined),
      hasCartel: NotNullControl<boolean>(false),
      viewerPosition: NotNullControl<string | undefined>(undefined),
      viewerRotation: NotNullControl<string | undefined>(undefined),

      cartelScale: NotNullControl<string | undefined>(undefined),
      cartelPosition: NotNullControl<string | undefined>(undefined),
      cartelRotation: NotNullControl<string | undefined>(undefined),
      viewerScale: NotNullControl<string | undefined>(undefined),
      supportType: NotNullControl<ContentSupportType | undefined>(undefined),
      priceUSD: NotNullControl<number | undefined>(undefined),
    });
    // sourceFile?: string;
    // previewFile?: string;
    // platformFile?: string;
  }
}
