import { APIAccesses } from './auth/api-accesses.entity';
import { Request } from 'express';
import { AuthCredentials } from './auth/auth-credentials.entity';
import { ApiTokenPayload } from './auth.dto';
import { CoreEntity } from '../core-entity';

export interface RequestUser extends Request {
  user: ApiTokenPayload;
}

export type UserIdentities = Pick<AuthCredentials, 'email' | 'username'>;

export type UserAccesses = Omit<
  APIAccesses,
  keyof CoreEntity | 'user' | 'userID' | 'authenticID'
>;

export interface CredentialTokens {
  access_token?: string;
  refresh_token?: string;
}
