<div class="card artwork-card">
  <app-tabs [tabs]="tabsConfigs" [(currentTab)]="currentTab"></app-tabs>
  <button (click)="navigateToArtworks()" class="close-button blank">X</button>

  <div class="card-body">
    <form *ngIf="formGroup" [formGroup]="formGroup">
      <div class="tab info-tab" *ngIf="currentTab === ArtTabs.Information">
        <app-entity-info
          [formGroup]="formGroup"
          [publicImage]="(selectedArtwork$ | async)?.preview"
          [entity]="selectedArtwork$ | async"
          [readonlyImage]="true"
          [extraInfo]="{ type: 'Type: {$}' }"
          (nameChanged)="updateServer('name')"
        >
          <app-editable-date
            formControlName="creationDate"
            [fieldTitle]="translations['creationDate']"
            (currentValueChange)="updateServer('creationDate')"
          >
          </app-editable-date>
          <!-- <app-editable-localized-text
            fieldTitle="Medium"
            formControlName="medium"
            format="input"
            (currentValueChange)="updateServer('medium')"
          >
          </app-editable-localized-text> -->
          <app-editable-text
            fieldTitle="Medium"
            formControlName="medium"
            (currentValueChange)="updateServer('medium')"
          >
          </app-editable-text>

          <app-editable-vector
            [fieldTitle]="translations['viewerScale']"
            formControlName="viewerScale"
            (currentValueChange)="updateServer('viewerScale')"
            [axis]="dimensionsAxis"
            [configs]="{ scale: 100, unit: 'cm' }"
          ></app-editable-vector>
          <app-editable-number
            formControlName="priceUSD"
            [fieldTitle]="translations['price']"
            (currentValueChange)="updateServer('priceUSD')"
          >
          </app-editable-number>
          <app-editable-localized-text
            fieldTitle="Description"
            [defaultRows]="10"
            formControlName="description"
            (currentValueChange)="updateServer('description')"
          >
          </app-editable-localized-text>
        </app-entity-info>
      </div>

      <div class="tab cartel-tab" *ngIf="currentTab === ArtTabs.Position">
        <app-editable-vector
          [readonly]="true"
          fieldTitle="Position"
          formControlName="viewerPosition"
          (currentValueChange)="updateServer('viewerPosition')"
        ></app-editable-vector>
        <app-editable-vector
          [readonly]="true"
          fieldTitle="Rotation"
          formControlName="viewerRotation"
          (currentValueChange)="updateServer('viewerRotation')"
        ></app-editable-vector>
      </div>
    </form>

    <div class="tab file-tab" *ngIf="currentTab === ArtTabs.Files">
      <ng-container *ngIf="artist$ | async; let artist">
        <ng-container *ngIf="selectedExposition$ | async; let expo">
          <app-artists-files
            *ngIf="selectedArtwork$ | async; let content"
            [contentID]="content.id"
            [artistID]="artist.id"
            [expoID]="expo.id"
            (memoryUpdated)="checkTabsValidity()"
          ></app-artists-files>
        </ng-container>
      </ng-container>
    </div>

    <div class="tab cartel-tab" *ngIf="currentTab === ArtTabs.Cartel">
      <ng-container *ngIf="artist$ | async; let artist">
        <ng-container *ngIf="selectedExposition$ | async; let expo">
          <app-artist-cartel
            *ngIf="selectedArtwork$ | async; let content"
            [contentID]="content.id"
            [artistID]="artist.id"
            [expoID]="expo.id"
            [artistName]="artist.name"
          ></app-artist-cartel>
        </ng-container>
      </ng-container>
    </div>
  </div>
</div>
