<div class="form-control">
  <span class="label">
    {{ fieldTitle | localize }}
  </span>
  <div class="field-with-edit">
    <span class="value" *ngIf="!isEditing" [style]="textStyle">
      {{ currentValue | date }}
    </span>
    <nz-date-picker *ngIf="isEditing" [(ngModel)]="currentValue">
    </nz-date-picker>
    <span
      *ngIf="!readonly"
      class="field-edit-icon"
      nz-icon
      nzType="edit"
      nzTheme="outline"
      (click)="toggleEdit()"
    ></span>
  </div>
</div>
