export const reverseRecord = <
  TKey extends string | number,
  TValue extends string | number,
>(
  base: Record<TKey, TValue>,
): Record<TValue, TKey> => {
  return Object.keys(base).reduce((dict, curr) => {
    dict[base[curr as TKey]] = curr as TKey;
    return dict;
  }, {} as Record<TValue, TKey>);
};
