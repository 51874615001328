import {
  HttpErrorResponse,
  HttpEventType,
  HttpResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  MemoryEncodingRequestDTO,
  MemoryEncodingResponseDTO,
  Memory,
} from '@apophenia/platform';
import { NgxSpinnerService } from 'ngx-spinner';
import { catchError, firstValueFrom, skipWhile, tap } from 'rxjs';
import { MAIN_SPINNER_NAME } from 'src/app/app.component';
import { NotificationsService } from 'src/app/shared/notifications/notifications.service';
import { APIService } from 'src/app/shared/services/api.service';
import { FileUploadsService } from 'src/app/shared/services/file-uploads.service';

export const getFileType = (file: File): string => {
  return file.name.endsWith('glb') ? 'model/gltf-binary' : file.type;
};

export interface FileUploadRequest {
  uploadTag?: string;
  file: File;
}

@Injectable({ providedIn: 'root' })
export class MemoryService {
  constructor(
    protected apiService: APIService,
    protected notification: NotificationsService,
    protected spinner: NgxSpinnerService,
    private fileUpload: FileUploadsService,
  ) {}

  async uploadNewFile(
    baseURL: string,
    encodingDTO: Partial<
      Omit<MemoryEncodingRequestDTO, 'fileSize' | 'fileType'>
    > &
      Record<string, unknown>,
    fileUpload: FileUploadRequest,
  ): Promise<Memory> {
    void this.spinner.show(MAIN_SPINNER_NAME);
    const dto: MemoryEncodingRequestDTO = {
      ...encodingDTO,
      fileSize: fileUpload.file.size,
      fileType: getFileType(fileUpload.file),
      fileName: encodingDTO.fileName ?? fileUpload.file.name,
    } as MemoryEncodingRequestDTO;

    const encodingResponse =
      await this.apiService.post<MemoryEncodingResponseDTO>(baseURL, dto);
    const uploadTag = fileUpload.uploadTag ?? 'default';
    await firstValueFrom(
      this.fileUpload
        .uploadFileAWSS3(
          encodingResponse.uri,
          fileUpload.file,
          dto.fileType,
          uploadTag,
        )
        .pipe(
          tap((event) => {
            if (event.type == HttpEventType.UploadProgress) {
              this.fileUpload.currentProjectProgress$.next(
                event.loaded / (event.total as number),
              );
            }
          }),
          skipWhile(
            (event) =>
              !(event instanceof HttpResponse) &&
              !(event instanceof HttpErrorResponse),
          ),
          tap(() => {
            this.fileUpload.currentProjectProgress$.next(-1);
            this.fileUpload.uploadCompleted(uploadTag);
          }),
          catchError((res: HttpErrorResponse) => {
            this.fileUpload.currentProjectProgress$.next(-1);
            this.fileUpload.uploadCompleted(uploadTag);
            if (
              res.status === 0 ||
              res.status == undefined ||
              (res.status >= 400 && res.status <= 599)
            ) {
              this.notification.error(
                'Une erreur est survenue lors du téléversement du fichier',
              );
            }
            void this.spinner.hide(MAIN_SPINNER_NAME);
            throw res.error;
          }),
        ),
    );
    const memory = await this.apiService.patch<Memory>(
      `${baseURL}/${encodingResponse.memoryID}/confirm`,
      {},
    );

    void this.spinner.hide(MAIN_SPINNER_NAME);
    return memory;
  }

  async removeFile(baseURL: string, memoryID: string): Promise<void> {
    return await this.apiService.delete(`${baseURL}/${memoryID}`);
  }
}
