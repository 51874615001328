import { User, UserInvitation } from '@apophenia/platform';

export const getFullUserName = (user: User | UserInvitation): string => {
  let name = '';
  const givenName =
    (user as User).metadata?.given_name ?? (user as UserInvitation).given_name;
  const familyName =
    (user as User).metadata?.family_name ??
    (user as UserInvitation).family_name;
  if (givenName) {
    name += givenName;
    if (familyName) {
      name += ' ';
    }
  }
  if (familyName) {
    name += familyName;
  }
  if (name.length == 0) {
    name = 'Incomplet';
  }
  return name;
};
