import { Directive, Pipe, PipeTransform } from '@angular/core';
import { LocalizedString } from '@apophenia/platform';
import { LocalizeService } from 'src/app/shared/localization/locale.service';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[localize]',
})
export class LocalizeDirective {
  //constructor() {} // private sharedTexts: LocalizedDict, // @Inject(LocalizationSharedInjectedDataToken)
}

@Pipe({
  name: 'localize',
})
export class LocalizePipe implements PipeTransform {
  constructor(private localizeService: LocalizeService) {} // private sharedTexts: LocalizedDict, // @Inject(LocalizationSharedInjectedDataToken)

  transform(value?: string | LocalizedString): string | undefined {
    if (typeof value == 'object') {
      return value[this.localizeService.currentLocal];
    }
    return value;
  }
}
