import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup, FormGroupDirective } from '@angular/forms';
import { Memory } from '@apophenia/platform';
import { MemoryFileUpdateEvent } from 'src/app/shared/components/platform-files/platform-files-info/platform-files-info.component';
import { MISSING_PICTURE_IMAGE } from 'src/app/shared/models/assets.models';

const defaultInfo: Record<PropertyKey, string> = {
  id: 'ID: {$}',
};

@Component({
  selector: 'app-entity-info',
  templateUrl: './entity-info.component.html',
  styleUrls: ['./entity-info.component.scss'],
  // providers: [
  //   {
  //     provide: NG_VALUE_ACCESSOR,
  //     useClass: EntityInfoCardComponent,
  //     multi: true,
  //   },
  // ],
})
export class EntityInfoCardComponent implements OnInit {
  @Input() readonly = false;
  @Input() readonlyImage = false;
  @Input() entity?: ({ id: string } & unknown) | null;
  @Input() publicImage?: Memory;
  @Input() hasImage = true;
  @Input() extraInfo?: Record<PropertyKey, string>;

  @Output() nameChanged = new EventEmitter<void>();
  @Output() imageChanged = new EventEmitter<MemoryFileUpdateEvent>();

  extraInfoLabels: string[] = [];

  missingImage = MISSING_PICTURE_IMAGE;

  constructor(private form: FormGroupDirective) {}

  get formGroup(): FormGroup {
    return this.form.form;
  }

  onImageChange(file: File): void {
    this.imageChanged.emit({ file, memory: this.publicImage });
  }

  ngOnInit(): void {
    const extraInfo = { ...defaultInfo, ...(this.extraInfo ?? {}) };
    const entity = this.formGroup.value as Record<string, string>;
    this.extraInfoLabels = Object.keys(extraInfo).reduce((labels, prop) => {
      labels.push(extraInfo[prop].replace('{$}', entity[prop]));
      return labels;
    }, [] as string[]);
  }
}
