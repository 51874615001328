import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import {
  CreateArtistDTO,
  LocalizedString,
  UpdateExpositionDTO,
} from '@apophenia/platform';
import dayjs from 'dayjs';
import {
  BehaviorSubject,
  Observable,
  distinctUntilChanged,
  firstValueFrom,
  map,
  switchMap,
  take,
  tap,
  withLatestFrom,
} from 'rxjs';
import {
  ArtistExpositionViewModel,
  ArtistViewModel,
} from 'src/app/pages/artists/artists.models';
import { ArtistsService } from 'src/app/pages/artists/artists.service';
import { TabsModuleConfigs } from 'src/app/shared/components/multiple-tabs/multiple-tabs.component';
import { SelectOption } from 'src/app/shared/components/selector/selector.component';
import { LocalizedDict } from 'src/app/shared/localization/lozalize.models';
import { Countries } from 'src/app/shared/models/countries';
import { yesNoChoices } from 'src/app/shared/models/translations.models';
import { CurrentUsersService } from 'src/app/shared/services/current-users.service';
import {
  EntityFormControls,
  NotNullControl,
} from 'src/app/shared/utils/form-controls';

type ArtistFormControls = EntityFormControls<
  Omit<
    CreateArtistDTO,
    'birthDay' | 'birthYear' | 'birthMonth' | 'nationalities' | 'userID'
  > & {
    id?: string;
    nationalities: string[];
    birthDate?: Date;
  }
>;
type ExpositionFormControls = EntityFormControls<UpdateExpositionDTO>;

enum ArtistTabs {
  Information,
  Expositions,
  Admin,
}

const ARTIST_TABS: TabsModuleConfigs = [
  {
    label: 'Information',
  },
  {
    label: 'Expositions',
  },
  {
    label: 'Admin',
    adminOnly: true,
  },
];

@Component({
  selector: 'app-artists-card',
  templateUrl: './artist-card.component.html',
  styleUrls: ['./artist-card.component.scss'],
})
export class ArtistCardComponent {
  yesNoChoices = yesNoChoices;
  countriesOptions: SelectOption<string>[] = Countries.map((x) => ({
    value: x,
    label: x,
  }));

  ArtistTabs = ArtistTabs;
  artistTabsConfigs = ARTIST_TABS;
  currentTab: ArtistTabs = 0;

  isAdmin$: Observable<boolean>;

  profileFormGroup!: FormGroup<ArtistFormControls>;
  expositionFormGroup!: FormGroup<ExpositionFormControls>;

  selectedArtist$: Observable<ArtistViewModel | undefined>;
  expositions$: Observable<ArtistExpositionViewModel[]>;
  expositionChoices$: Observable<SelectOption<string>[]>;
  currentExpositionID$ = new BehaviorSubject<string | undefined>(undefined);
  currentExposition$: Observable<ArtistExpositionViewModel | undefined>;

  translations: LocalizedDict = {
    visible: { EN: 'Visible on the platform', FR: 'Visible sur la plateforme' },
    activeExposition: {
      EN: 'Active exposition',
      FR: 'Exposition active',
    },
    nationalities: {
      EN: 'Nationalities',
      FR: 'Nationalités',
    },
    birthPlace: {
      EN: 'Birth Country',
      FR: 'Pays de Naissance',
    },
    birthDate: {
      EN: 'Birth Date',
      FR: 'Date de Naissance',
    },
    artwork: { EN: 'Artworks', FR: 'Oeuvres' },
  };

  constructor(
    private currentUser: CurrentUsersService,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private artistService: ArtistsService,
  ) {
    this.isAdmin$ = this.currentUser.isAdmin$;
    this.selectedArtist$ = this.route.params.pipe(
      switchMap((params) =>
        this.artistService.selectById(
          (params.id as string) ?? this.currentUser.artistID,
        ),
      ),
      tap((artist) => {
        if (this.profileFormGroup?.value?.id != artist?.id) {
          this.updateProfileForm(artist);
          this.currentExpositionID$.next(
            artist?.activeExposition ?? artist?.expositions?.[0]?.id,
          );
        }
      }),
    );
    this.expositions$ = this.selectedArtist$.pipe(
      map((a) => (!a ? [] : a.expositions)),
    );
    this.currentExposition$ = this.currentExpositionID$.pipe(
      distinctUntilChanged(),
      withLatestFrom(this.expositions$),
      map(([selected, expos]) => expos.find((x) => x.id == selected)),
      tap((x) => this.updateExpoForm(x)),
    );
    this.expositionChoices$ = this.selectedArtist$.pipe(
      map((a) => (!a ? [] : a.expositions)),
      map((x) => x.map((e) => ({ label: e.name, value: e.id }))),
    );
    this.createFormGroups();
  }

  onExpositionChanged(expoID?: string): void {
    this.currentExpositionID$.next(expoID);
  }

  async navigateToArtworks(): Promise<void> {
    const artist = await firstValueFrom(this.selectedArtist$.pipe(take(1)));
    if (!artist?.id) {
      return;
    }
    void this.router.navigate([`/artist/${artist.id}/artworks`]);
  }

  async updateServer(key: keyof ArtistFormControls): Promise<void> {
    if (!this.profileFormGroup.controls.id.value) {
      return;
    }
    let dto: Partial<CreateArtistDTO> & { birthDate?: Date } = {
      [key]: this.profileFormGroup.controls[key].value,
    };
    console.log(dto);
    if (key == 'birthDate') {
      if (!dto[key]) {
        return;
      }
      const date = dayjs(dto[key]);
      dto = {
        birthDay: date.get('date'),
        birthMonth: date.get('month'),
        birthYear: date.get('year'),
      };
    }
    await this.artistService.updateOne(
      this.profileFormGroup.controls.id.value,
      dto,
    );
  }

  async updateExposition(): Promise<void> {
    const values = this.expositionFormGroup.value;
    const artist = await firstValueFrom(this.selectedArtist$.pipe(take(1)));
    if (!values?.id || !artist?.id) {
      return;
    }
    await this.artistService.createOrUpdateExposition(artist.id, {
      exposition: values as UpdateExpositionDTO,
    });
  }

  private updateProfileForm(artist?: ArtistViewModel): void {
    this.profileFormGroup.setValue({
      id: artist?.id,
      name: artist?.name,
      authenticID: artist?.authenticID,
      visible: artist?.visible,
      activeExposition: artist?.activeExposition,
      bio: artist?.bio,
      nationalities: artist?.nationalities?.split(',') ?? [],
      birthPlace: artist?.birthPlace,
      birthDate: !artist
        ? undefined
        : dayjs()
            .set('year', artist.birthYear)
            .set('month', artist.birthMonth)
            .set('date', artist.birthDay)
            .toDate(),
    });
  }

  private updateExpoForm(expo?: ArtistExpositionViewModel): void {
    this.expositionFormGroup.setValue({
      id: expo?.id,
      name: expo?.name ?? '',
      description: expo?.description,
    });
  }

  private createFormGroups(): void {
    this.profileFormGroup = this.formBuilder.group<ArtistFormControls>({
      id: NotNullControl<string | undefined>(undefined),
      name: NotNullControl('', [Validators.required]),
      authenticID: NotNullControl('', [Validators.required]),
      visible: NotNullControl(false),
      activeExposition: NotNullControl<string | undefined>(undefined),
      bio: NotNullControl<LocalizedString | undefined>(undefined),
      nationalities: NotNullControl<string[]>([]),
      birthPlace: NotNullControl<string | undefined>(undefined),
      birthDate: NotNullControl<Date | undefined>(undefined),
    });
    this.expositionFormGroup = this.formBuilder.group<ExpositionFormControls>({
      id: NotNullControl<string | undefined>(undefined, [Validators.required]),
      description: NotNullControl<LocalizedString | undefined>(undefined, [
        Validators.required,
      ]),
      name: NotNullControl('', [Validators.required]),
    });
  }
}
