import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import {
  CreateOrganismDTO,
  CreateOrganismHubDTO,
  DeviceTypes,
  OrganismFileTypes,
} from '@apophenia/platform';
import { Observable, map, tap, withLatestFrom } from 'rxjs';
import { OrganismViewModel } from 'src/app/pages/organisms/organisms.models';
import { OrganismsService } from 'src/app/pages/organisms/organisms.service';
import { TabsModuleConfigs } from 'src/app/shared/components/multiple-tabs/multiple-tabs.component';
import { MemoryFileUpdateEvent } from 'src/app/shared/components/platform-files/platform-files-info/platform-files-info.component';
import { LocalizedDict } from 'src/app/shared/localization/lozalize.models';
import { CurrentUsersService } from 'src/app/shared/services/current-users.service';
import {
  EntityFormControls,
  NotNullControl,
} from 'src/app/shared/utils/form-controls';
import { firstValuePromise } from 'src/app/shared/utils/promisify';

type OrgFormControls = EntityFormControls<Partial<CreateOrganismDTO>>;

enum OrganismTabs {
  'Information',
  'Hub',
  // 'Documents',
  'Admin',
}
const organismCardTabs: TabsModuleConfigs = [
  {
    label: 'Information',
  },
  {
    label: 'Hub',
  },
  // {
  //   label: 'Documents',
  // },
  {
    label: 'Admin',
    adminOnly: true,
  },
];

@Component({
  selector: 'app-organisms-card',
  templateUrl: './organisms-card.component.html',
  styleUrls: ['./organisms-card.component.scss'],
})
export class OrganismCardComponent {
  organismTabsConfigs = organismCardTabs;
  OrganismFileTypes = OrganismFileTypes;
  OrganismTabs = OrganismTabs;
  currentTab: OrganismTabs = 0;

  formGroup?: FormGroup<OrgFormControls>;
  isAdmin$: Observable<boolean>;

  //selectedOrganism?: OrganismViewModel = undefined;
  selectedOrganism$: Observable<OrganismViewModel | undefined>;

  translations: LocalizedDict = {
    location: { EN: 'Location', FR: 'Emplacement' },
    category: { EN: 'Category', FR: 'Catégorie' },
    payment: { EN: 'Expected payment date', FR: 'Date du prochain paiement' },
    hub: {
      EN: "Management of your platform's main hub",
      FR: 'Gestion du hub principal de votre plateforme',
    },
    isDev: { EN: 'In Development', FR: 'En Développement' },
  };

  constructor(
    private currentUserService: CurrentUsersService,
    private organismService: OrganismsService,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
  ) {
    this.isAdmin$ = this.currentUserService.isAdmin$;
    this.createFormGroup();
    this.selectedOrganism$ = this.route.params.pipe(
      withLatestFrom(this.organismService.selectAll()),
      map(([params, orgs]) => orgs.find((o) => o.id == params.id)),
      tap((org) => {
        if (this.formGroup?.value?.id != org?.id) {
          this.updateFormGroup(org);
        }
      }),
    );
  }

  async updateServer(key: keyof OrgFormControls): Promise<void> {
    const dto: Partial<CreateOrganismDTO> = {
      [key]: this.formGroup?.controls[key].value,
    };
    await this.organismService.updateOne(
      this.formGroup?.controls.id.value as string,
      dto,
    );
  }

  async uploadFile(
    file: MemoryFileUpdateEvent,
    type: OrganismFileTypes,
  ): Promise<void> {
    const params = await firstValuePromise(this.route.params);
    if (!params?.id) {
      return;
    }
    const dto: Partial<CreateOrganismHubDTO> = {
      id: file.memory?.id,
      device:
        (type == OrganismFileTypes.Hub
          ? file.memory?.device
          : DeviceTypes.Universal) ?? DeviceTypes.Universal,
      memoryType: type,
    };
    await this.organismService.uploadFile(
      params.id as string,
      {
        fileUpload: { file: file.file },
        dto,
      },
      'files',
    );
  }

  navigateToOrganism(org?: OrganismViewModel): void {
    if (!org) {
      void this.router.navigate(['/organisms']);
    } else {
      void this.router.navigate([`/organisms/${org.id}`], {});
    }
  }

  private updateFormGroup(org?: OrganismViewModel): void {
    this.formGroup?.setValue({
      id: org?.id,
      name: org?.name,
      freeHostingEnd: org?.freeHostingEnd,
      location: org?.location,
      isDev: org?.isDev ?? false,
      category: org?.category,
    });
  }
  private createFormGroup(): void {
    this.formGroup = this.formBuilder.group<OrgFormControls>({
      id: NotNullControl('', [Validators.required]),
      name: NotNullControl('', [Validators.required]),
      freeHostingEnd: NotNullControl<Date | undefined>(undefined),
      location: NotNullControl('', [Validators.required]),
      isDev: NotNullControl(false),
      category: NotNullControl(''),
    });
  }
}
