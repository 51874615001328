<!-- <app-action-bar>
    <app-organisms-selector
      [selectedOrganism]="selectedOrganism$ | async"
      (selectedOrganismChange)="navigateToOrganism($event)"
    ></app-organisms-selector>
  </app-action-bar> -->
<div class="card org-card">
  <app-tabs [tabs]="organismTabsConfigs" [(currentTab)]="currentTab"></app-tabs>
  <button [routerLink]="'/organisms'" class="close-button blank">X</button>

  <div class="card-body">
    <form *ngIf="formGroup" [formGroup]="formGroup">
      <div class="tab info-tab" *ngIf="currentTab === OrganismTabs.Information">
        <app-entity-info
          [formGroup]="formGroup"
          [publicImage]="(selectedOrganism$ | async)?.thumbnail"
          [entity]="selectedOrganism$ | async"
          [readonly]="(isAdmin$ | async) !== true"
          (nameChanged)="updateServer('name')"
          (imageChanged)="uploadFile($event, OrganismFileTypes.Visual)"
        >
          <app-editable-text
            formControlName="location"
            [fieldTitle]="translations['location']"
            (currentValueChange)="updateServer('location')"
          >
          </app-editable-text>
          <app-editable-text
            formControlName="category"
            [fieldTitle]="translations['category']"
            (currentValueChange)="updateServer('category')"
          >
          </app-editable-text>
          <!-- <div class="form-control" *ngIf="isAdmin$ | async">
              <span class="label">Date du prochain paiement</span>
              <span>{{
                formGroup.controls.freeHostingEnd.value ?? 'Aucune'
              }}</span>
            </div> -->
        </app-entity-info>
      </div>
      <div class="tab hub-tab" *ngIf="currentTab === OrganismTabs.Hub">
        <app-platform-files
          [memories]="(selectedOrganism$ | async)?.hub ?? []"
          (memoryUpdated)="uploadFile($event, OrganismFileTypes.Hub)"
        ></app-platform-files>
      </div>
      <div class="tab info-tab" *ngIf="currentTab === OrganismTabs.Admin">
        <div class="section">
          <!-- <div class="form-control" *ngIf="isAdmin$ | async">
            <span>Début de la récurrence</span>
            <nz-date-picker formControlName="freeHostingEnd"></nz-date-picker>
          </div> -->
          <div class="form-control">
            <span class="label">{{ translations['isDev'] | localize }}</span>
            <app-yes-no-radio *ngIf="isAdmin$ | async" formControlName="isDev">
            </app-yes-no-radio>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
