<button
  type="button"
  (click)="fileInput.click()"
  [disabled]="disabled"
  class="{{ buttonClass }} inverse"
  [style.width]="buttonWidth"
>
  <i
    *ngIf="iconContent !== undefined"
    nz-icon
    nzType="{{ iconContent }}"
    nzTheme="outline"
  ></i>
  <ng-container *ngIf="iconContent === undefined">
    {{ textContent }}</ng-container
  >
  <input
    #fileInput
    [accept]="fileType"
    type="file"
    (change)="onFilesSelected($event)"
    style="display: none"
  />
</button>
