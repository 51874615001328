import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-image-card',
  templateUrl: './image-card.component.html',
  styleUrls: ['./image-card.component.scss'],
})
export class ImageCardItemComponent {
  @Input() readonly = false;
  @Input() img?: string;
  @Input() title?: string;
  @Output() fileChanged = new EventEmitter<File>();

  onImageAdded(files: File[]): void {
    if (!this.readonly && files.length > 0) {
      this.fileChanged.emit(files[0]);
    }
  }
}
