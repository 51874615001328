import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MultipleTabsComponent } from 'src/app/shared/components/multiple-tabs/multiple-tabs.component';

@NgModule({
  declarations: [MultipleTabsComponent],
  imports: [CommonModule],
  exports: [MultipleTabsComponent],
})
export class MultipleTabsModule {}
