import { Class } from '../utils';
import 'reflect-metadata';

export const getConstructor = <T>(type: Class<T> | object): Class<T> => {
  if (typeof type == 'object') {
    return type.constructor as Class<T>;
  } else {
    return type;
  }
};

export type ClassDecorator = (target: Class) => void;

export type ParamDecorator = (
  target: object,
  propertyKey: string,
  index: number,
) => void;

export type PropertyDecorator = (target: object, propertyKey: string) => void;

export type AmbivalentDecorator = (
  target: Class | object,
  propertyKey?: string,
  index?: number | TypedPropertyDescriptor<(...c: any) => Promise<any>>,
) => void;

export function updateMetadata<TMeta>(
  metaType: Class<TMeta>,
  target: Class,
  processor: (initialValue: TMeta) => void,
  metadataKey: string,
): void;
export function updateMetadata<TMeta>(
  metaType: Class<TMeta>,
  target: Class,
  obj: Partial<TMeta>,
  metadataKey: string,
): void;

export function updateMetadata<TMeta>(
  metaType: Class<TMeta>,
  target: Class,
  processorObj: (initialValue: TMeta) => void | Partial<TMeta>,
  metadataKey: string,
): void {
  let obj = Reflect.getMetadata(metadataKey, getConstructor(target)) as TMeta;
  if (!obj) {
    obj = new metaType();
  }
  if (typeof processorObj == 'function') {
    processorObj(obj);
  } else {
    for (const key of Object.keys(processorObj)) {
      if (!!obj[key] && Array.isArray(processorObj[key])) {
        (obj[key] as unknown[]).push(...(processorObj[key] as unknown[]));
      } else {
        Object.assign(obj, { [key]: processorObj[key] });
      }
    }
  }
  Reflect.defineMetadata(metadataKey, obj, getConstructor(target));
}
