import { CoreEntity } from '../core-entity';
import { AmnesicContainerColumn } from '../amnesia';
import { ProjectTypes, ProjectTemplate, TemplateProject } from './templates';

export enum ProjectCategories {
  Educational = 'Educational',
  Exposition = 'Exposition',
  AudioExperience = 'AudioExperience',
  VisualCuration = 'VisualCuration',
  ArtShop = 'ArtShop',
}

export interface Project extends CoreEntity, TemplateProject {
  organismID: string;
  name: string;
  manifests: AmnesicContainerColumn;

  metadata: ProjectTemplate;
  template: ProjectTypes;

  platformVersion: string;
  isDev: boolean;
  isPrivate: boolean;
  isFree: boolean;
  categories: string[];
}
