import { ArtistExposition } from './artist-exposition.entity';
import { CoreEntity } from '../core-entity';
import { LocalizedString, SupportedLocals } from '../localization';
import { Memory } from '../amnesia';

export const artistMemoryPrefix = (entity: Artist): string =>
  `artists/${entity.id}`;

export interface Artist extends CoreEntity {
  name: string;

  birthYear: number;
  birthMonth: number;
  birthDay: number;
  birthPlace: string;
  nationalities: string;
  bio: LocalizedString;
  language: SupportedLocals;
  country: string;
  province: string;
  city: string;
  visible: boolean;
  memoryContext: Memory[];
  avatar?: Memory;
  /*######################*/
  /*######################*/

  expositions?: ArtistExposition[];
  activeExposition?: string;

  /*######################*/
  /*######################*/
  authenticID: string;
}
