import { Injectable } from '@angular/core';
import {
  Artist,
  ArtistExposition,
  ArtistExpositionGroups,
  ArtistExpositionMemoryGroup,
  CreateExpositionContentDTO,
  EncodeArtistExpositionMemoryDTO,
  EncodeArtistMemoryDTO,
  ExpositionContent,
  Memory,
  UpdateArtistExpositionDTO,
  UpdateArtistContentPosition,
  User,
} from '@apophenia/platform';
import dayjs from 'dayjs';
import { Observable, map } from 'rxjs';
import { MAIN_SPINNER_NAME } from 'src/app/app.component';
import {
  ArtistViewModel,
  ExpositionContentViewModel,
} from 'src/app/pages/artists/artists.models';
import { getFullUserName } from 'src/app/pages/users/users.models';
import {
  CommonDataService,
  DataState,
} from 'src/app/shared/services/_base-data.service';
import { FileUploadRequest } from 'src/app/shared/services/memory.service';
import { mergeDeep } from 'src/app/shared/utils/merge-deep';

// export interface EncodeArtistMemoryDTO extends FileUploadRequest {
//   dto: Omit<MemoryEncodingRequestDTO, 'isPublic' | 'device' | 'groupID'> & {
//     contentID?: string;
//     group: ArtistExpositionGroups;
//   };
// }

export interface EncodeArtistMemory {
  fileUpload: FileUploadRequest;
  dto: EncodeArtistMemoryDTO;
}
export type ArtistContentMemoryDTO = Pick<
  EncodeArtistExpositionMemoryDTO,
  'contentID' | 'group' | 'id'
>;
export interface EncodeArtistContentMemory {
  fileUpload: FileUploadRequest;
  dto: ArtistContentMemoryDTO;
}

const fileMap: Record<ArtistExpositionGroups, keyof ExpositionContent> = {
  [ArtistExpositionGroups.Optimized]: 'platformFile',
  [ArtistExpositionGroups.Preview]: 'previewFile',
  [ArtistExpositionGroups.Source]: 'sourceFile',
  [ArtistExpositionGroups.Thumbnail]: 'previewFile',
};

@Injectable({ providedIn: 'root' })
export class ArtistsService extends CommonDataService<Artist, ArtistViewModel> {
  private artistUploadTag = 'artist-file-upload';

  protected get baseURL(): string {
    return '/artists';
  }

  selectContent(
    artistID: string,
    expoID: string,
    contentID: string,
  ): Observable<ExpositionContentViewModel> {
    return this.selectById(artistID).pipe(
      map((artist) => {
        const vm = artist.expositions
          .find((x) => x.id == expoID)
          ?.content.find(
            (x) => x.id == contentID,
          ) as ExpositionContentViewModel;
        return vm;
      }),
    );
  }

  async createOrUpdateExposition(
    artistID: string,
    dto: UpdateArtistExpositionDTO,
  ): Promise<void> {
    const exposition = await this.apiService.put<ArtistExposition>(
      `${this.baseURL}/${artistID}/expositions`,
      dto,
      dto.exposition.id
        ? 'Exposition modifiée avec succès'
        : 'Exposition ajoutée avec succès',
    );
    this.addOrUpdateExposition(artistID, exposition, exposition.id);
  }

  async removeExposition(artistID: string, expoID: string): Promise<void> {
    const exposition = await this.apiService.delete<ArtistExposition>(
      `${this.baseURL}/${artistID}/expositions/${expoID}`,
    );
    this.updateOneEntity(artistID, {
      expositions: (this.getRawEntity(artistID).expositions ?? []).filter(
        (x) => x.id != exposition.id,
      ),
    });
  }

  async updateArtistAvatar(
    artistID: string,
    dto: EncodeArtistMemory,
  ): Promise<void> {
    const newFile = await this.memoryService.uploadNewFile(
      `${this.baseURL}/${artistID}/files`,
      dto.dto,
      { uploadTag: this.artistUploadTag, ...dto.fileUpload },
    );
    const artist = this.state.raw[artistID];
    artist.memoryContext ??= [];
    const matchingMemory = artist.memoryContext.findIndex(
      (x) => x.id == newFile.id,
    );
    if (matchingMemory >= 0) {
      artist.memoryContext[matchingMemory] = newFile;
    } else {
      artist.memoryContext.push(newFile);
    }
    this.updateOneEntity(artistID, artist);
  }

  async createNFT(
    artistID: string,
    expoID: string,
    contentID: string,
  ): Promise<void> {
    const content = await this.apiService.post<ExpositionContent>(
      `${this.baseURL}/${artistID}/expositions/${expoID}/content/${contentID}/nft`,
      {},
    );
    const exposition = this.getRawEntity(artistID).expositions?.find(
      (x) => x.id == expoID,
    ) as ArtistExposition;

    const contentIndex =
      exposition.content?.findIndex((x) => x.id == contentID) ?? -1;
    (exposition.content as ExpositionContent[])[contentIndex] = content;
    this.addOrUpdateExposition(artistID, exposition, expoID);
  }

  async createOrUpdateContent(
    artistID: string,
    expoID: string,
    dto: Partial<CreateExpositionContentDTO> & {
      id: string | undefined | null;
    },
    fileUpdate?: EncodeArtistContentMemory,
  ): Promise<void> {
    const exposition = this.getRawEntity(artistID).expositions?.find(
      (x) => x.id == expoID,
    ) as ArtistExposition;
    const contentIndex = dto.id
      ? exposition.content?.findIndex((x) => x.id == dto.id) ?? -1
      : -1;

    let content: ExpositionContent | undefined =
      exposition.content?.[contentIndex];
    if (Object.keys(dto).length > 1 || !content) {
      content = await this.apiService.patch<ExpositionContent>(
        `${this.baseURL}/${artistID}/expositions/${expoID}/content`,
        dto,
      );
    }
    if (contentIndex == -1) {
      exposition.content ??= [];
      exposition.content?.push(content);
    } else {
      (exposition.content as ExpositionContent[])[contentIndex] = content;
    }
    this.addOrUpdateExposition(artistID, exposition, expoID);

    if (fileUpdate) {
      const newFile = await this.memoryService.uploadNewFile(
        `${this.baseURL}/${artistID}/expositions/${expoID}/files`,
        { ...fileUpdate.dto, contentID: content.id },
        { uploadTag: this.artistUploadTag, ...fileUpdate.fileUpload },
      );
      this.replaceOrUpdateExpositionFile(
        artistID,
        expoID,
        { ...newFile, ...fileUpdate.dto, id: newFile.id ?? fileUpdate.dto.id },
        content.id,
      );
    }
  }
  async updateContentPosition(
    artistID: string,
    expoID: string,
    dto: UpdateArtistContentPosition[],
  ): Promise<void> {
    const exposition = await this.apiService.patch<ArtistExposition>(
      `${this.baseURL}/${artistID}/expositions/${expoID}/content/positions`,
      { contents: dto },
      MAIN_SPINNER_NAME,
      'Modifications enregistrées avec succès',
    );
    // exposition.memoryContext = this.getRawEntity(artistID).expositions?.find(
    //   (x) => x.id == expoID,
    // )?.memoryContext as Memory[];
    this.addOrUpdateExposition(artistID, exposition, expoID);
  }

  async removeContent(
    artistID: string,
    expoID: string,
    contentID: string,
  ): Promise<void> {
    await this.apiService.delete<ExpositionContent>(
      `${this.baseURL}/${artistID}/expositions/${expoID}/content/${contentID}`,
    );
    const exposition = this.getRawEntity(artistID).expositions?.find(
      (x) => x.id == expoID,
    ) as ArtistExposition;

    const expositions = this.getRawEntity(artistID).expositions ?? [];
    this.updateOneEntity(artistID, {
      expositions: [
        ...expositions.filter((x) => x.id != exposition.id),
        {
          ...mergeDeep(
            exposition,
            expositions.find((x) => x.id == exposition.id) as ArtistExposition,
          ),
          content: exposition.content?.filter((x) => x.id != contentID),
        },
      ],
    });
  }

  async updateExpoThumbnail(
    artistID: string,
    expoID: string,
    dto: EncodeArtistContentMemory,
  ): Promise<void> {
    const newFile = await this.memoryService.uploadNewFile(
      `${this.baseURL}/${artistID}/expositions/${expoID}/files`,
      { ...dto.dto },
      { uploadTag: this.artistUploadTag, ...dto.fileUpload },
    );
    this.replaceOrUpdateExpositionFile(artistID, expoID, newFile);
  }

  override async reloadData(): Promise<void> {
    if (!this.authService.tokenPayload$.value?.isAdmin) {
      const id = this.authService.tokenPayload$.value?.artist;
      if (!id) {
        return;
      }
      const currentArtist = await this.apiService.get<Artist>(
        `${this.baseURL}/${id}?sort=${this.sortBy}`,
        MAIN_SPINNER_NAME,
      );
      if (!currentArtist) {
        return;
      }
      const newState: DataState<Artist, ArtistViewModel> = {
        ids: [id],
        entities: { [id]: this.viewModel(currentArtist) },
        raw: { [id]: currentArtist },
      };
      this.state$.next(newState);
      this.loaded = true;
    } else {
      await super.reloadData();
    }
  }

  protected override viewModel(artist: Artist): ArtistViewModel {
    const user: User | undefined = undefined;
    const avatar =
      typeof artist?.memoryContext == 'string'
        ? this.state.entities[artist.id]?.avatar
        : artist?.memoryContext?.find((x) => x.groupID == 0);
    return {
      ...artist,
      user:
        user == undefined
          ? { name: 'Non-lié' }
          : {
              name: getFullUserName(user),
              url: `/users/:id`, //TODO
            },
      location: artist?.birthPlace ?? 'Aucun',
      creationDate: dayjs(artist.createdAt).format('YYYY-MM-DD'),
      avatar,
      expositions:
        artist.expositions?.map((expo) => {
          const memories = expo.memoryContext;
          return {
            ...expo,
            isMasterExpo: expo.id == artist.activeExposition,
            thumbnail: memories?.find(
              (x) => x.groupID == ArtistExpositionMemoryGroup['Thumbnail'],
            ),
            content:
              expo.content?.map((content) => {
                return {
                  ...content,
                  dimensionsLabel: !content.viewerScale
                    ? 'Aucune'
                    : content.viewerScale?.replace(/,/g, 'm X ') + 'm',
                  artistID: artist.id,
                  valid: !!content.viewerPosition,
                  source: content.sourceFile
                    ? memories?.find((x) => x.id == content.sourceFile)
                    : undefined,
                  optimized: content.platformFile
                    ? memories?.find((x) => x.id == content.platformFile)
                    : undefined,
                  preview: content.previewFile
                    ? memories?.find((x) => x.id == content.previewFile)
                    : undefined,
                };
              }) ?? [],
          };
        }) ?? [],
    };
  }

  private addOrUpdateExposition(
    artistID: string,
    newExpo: ArtistExposition,
    expoID?: string,
  ): void {
    const expositions = this.getRawEntity(artistID).expositions ?? [];
    const index = expositions.findIndex((x) => x.id == expoID);
    if (index == -1) {
      expositions.push(newExpo);
    } else {
      expositions[index] = newExpo;
    }
    this.updateOneEntity(artistID, { expositions });
  }

  private replaceOrUpdateExpositionFile(
    artistID: string,
    expoID: string,
    newFile: Memory,
    contentID?: string,
  ): void {
    const expositions = this.getRawEntity(artistID).expositions ?? [];
    const index = expositions.findIndex((x) => x.id == expoID);
    const memoryContext = expositions[index]?.memoryContext ?? [];
    const content = expositions[index]?.content ?? [];

    const fileIndex = memoryContext.findIndex((x) => x.id == newFile.id);
    if (fileIndex && fileIndex == -1) {
      memoryContext.push(newFile);
    } else if (fileIndex) {
      memoryContext[fileIndex] = newFile;
    }
    expositions[index].memoryContext = memoryContext;

    if (contentID) {
      const contentIndex = content.findIndex((x) => x.id == contentID);
      if (contentIndex) {
        content[contentIndex][
          fileMap[newFile.group as ArtistExpositionGroups] as 'sourceFile'
        ] = newFile.id;
        expositions[index].content = content;
      }
    }
    this.updateOneEntity(artistID, { expositions });
  }
}
