import { LocalizedString } from '../localization';
import { GenericModuleType } from './modules-metadata.entity';

export interface CreateModuleMetadataDTO {
  name: string;
  description: LocalizedString;
  type: GenericModuleType;
  isDev: boolean;
  platformVersion: string;
}
