import { AuthCredentials } from '../auth/auth-credentials.entity';
import { APIAccesses } from '../auth/api-accesses.entity';
import { UserMetadata } from './user-metadata.entity';
import { RefreshTokens } from '../auth/refresh-tokens.entity';
import { CoreEntity } from '../../core-entity';

export interface User extends CoreEntity {
  credentials: AuthCredentials;
  accesses: APIAccesses;
  metadata: UserMetadata;
  deviceRefreshToken: RefreshTokens[];
}
