<textarea
  nz-input
  [(ngModel)]="localString[currentLocal]"
  (ngModelChange)="onValueChanged()"
  name="description"
  type="text"
  id="description"
  [rows]="defaultRows"
></textarea>
<nz-select class="lang-select" [(ngModel)]="currentLocal">
  <nz-option
    *ngFor="let option of locals"
    [nzLabel]="option"
    [nzValue]="option"
  ></nz-option
></nz-select>
