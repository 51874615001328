import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { LocalizedString } from '@apophenia/platform';
import { SelectOption } from 'src/app/shared/components/selector/selector.component';
import { LocalizePipe } from 'src/app/shared/localization/lozalize.directive';
import { CurrentUsersService } from 'src/app/shared/services/current-users.service';

export interface TabDefinition {
  label?: string | LocalizedString;
  adminOnly?: boolean;
  disabled?: boolean;
}

export type TabsModuleConfigs = Record<number, TabDefinition>;

type TabChoice = SelectOption<number> & { disabled: boolean };

@Component({
  selector: 'app-tabs',
  templateUrl: './multiple-tabs.component.html',
  styleUrls: ['./multiple-tabs.component.scss'],
})
export class MultipleTabsComponent implements OnChanges {
  @Input() tabs!: TabsModuleConfigs;
  @Input() currentTab!: number;
  @Input() centered = false;
  @Output() currentTabChange = new EventEmitter<number>();

  @Input() color?: string;

  tabChoices: TabChoice[] = [];
  isAdmin = false;
  constructor(
    private localizePipe: LocalizePipe,
    private currentUserService: CurrentUsersService,
  ) {
    this.isAdmin = this.currentUserService.isAdmin;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.tabs?.currentValue) {
      this.tabChoices = (Object.keys(this.tabs) as unknown as number[])
        .map((x, i) => {
          return {
            adminOnly: this.tabs[x].adminOnly ?? false,
            disabled: this.tabs[x].disabled ?? false,
            value: i,
            label: this.localizePipe.transform(this.tabs[x].label) as string,
          };
        })
        .filter((x) => !x.adminOnly || (x.adminOnly && this.isAdmin));
    }
  }

  selectTab(value: number): void {
    this.currentTab = value;
    this.currentTabChange.emit(this.currentTab);
  }
}
