<div class="card modules-card">
  <app-tabs [tabs]="modulesTabsConfigs" [(currentTab)]="currentTab"></app-tabs>
  <button [routerLink]="'/modules'" class="close-button blank">X</button>
  <form *ngIf="formGroup" [formGroup]="formGroup">
    <div class="tab info-tab" *ngIf="currentTab === ModulesTabs.Information">
      <app-entity-info
        *ngIf="selectedModule$ | async; let modules"
        [formGroup]="formGroup"
        [publicImage]="modules?.thumbnail"
        [entity]="modules"
        (nameChanged)="updateServer('name')"
      >
        <div class="form-control">
          <span class="label">
            {{ translations['createdAt'] | localize }}
          </span>
          <span class="value">{{ modules?.createdAt | date }}</span>
        </div>
        <app-editable-localized-text
          [fieldTitle]="'Description'"
          [defaultRows]="10"
          formControlName="description"
          (currentValueChange)="updateServer('description')"
        >
        </app-editable-localized-text>
      </app-entity-info>
    </div>

    <div class="tab file-tab" *ngIf="currentTab === ModulesTabs.Scene">
      <app-platform-files
        [memories]="(selectedModule$ | async)?.scenes ?? []"
        (memoryUpdated)="uploadFile($event)"
      ></app-platform-files>
    </div>
    <div class="tab file-tab" *ngIf="currentTab === ModulesTabs.Audio"></div>
  </form>
</div>
