import { SupportedLocals } from '../../localization';

export class AuthenticArtistDetails {
  first_name: string;
  last_name: string;
  artistic_name: string;
}
export class AuthenticArtistBirthDetails {
  year: number;
  month: number;
  day: number;
  place?: string;
}
export class AuthenticArtistBio {
  short_bio: string;
  language: string;
}
export class AuthenticArtistDTO {
  personal_details: AuthenticArtistDetails;
  birth_details: AuthenticArtistBirthDetails;
  artist_bio: AuthenticArtistBio;
  nationalities: string;
  email: string;
}

export interface AuthenticImageData {
  picture_hash: string;
  artwork_url: string;
}
export interface AuthenticArtworkDTO {
  details: {
    title: string;
    description?: string;
    language?: SupportedLocals;
  };
  creation_date: {
    year: number;
    month?: number;
    day?: number;
  };
  dimensions: {
    units: 'cm';
    height: number;
    width: number;
    depth: number;
  };
  token_details: AuthenticImageData;
  artist_id: string;
  exhibition_id: string;
  price_usd: number;
}

export interface AuthenticArtistResponse {
  request_id: string;
  artist_id: string;
  message: 'Artist created';
}
export interface AuthenticArtworkResponse {
  request_id: string;
  artwork_id: string;
  message: 'Artwork created';
}
