import { Component } from '@angular/core';
import { Artist } from '@apophenia/platform';
import { Observable, map } from 'rxjs';
import { getFullUserName } from 'src/app/pages/users/users.models';
import { UsersService } from 'src/app/pages/users/users.service';
import { MISSING_USER_IMAGE } from 'src/app/shared/models/assets.models';

export type UsersListItem = Pick<Artist, 'id' | 'name'> & {
  img: string;
  url: string;
};

@Component({
  selector: 'app-users-list',
  templateUrl: './users-list.component.html',
  styleUrls: ['./users-list.component.scss'],
})
export class UsersListComponent {
  users$: Observable<UsersListItem[]>;

  constructor(private usersService: UsersService) {
    this.users$ = this.usersService.selectAll().pipe(
      map((users) => {
        return users.map((x) => ({
          id: x.id,
          name: getFullUserName(x),
          img: MISSING_USER_IMAGE,
          url: `/users/${x.id}`,
        }));
      }),
    );
  }
}
