<div class="card artist-card">
  <app-tabs [tabs]="artistTabsConfigs" [(currentTab)]="currentTab"></app-tabs>
  <button
    *ngIf="isAdmin$ | async"
    type="button"
    class="primary artwork-button"
    (click)="navigateToArtworks()"
  >
    {{ translations['artwork'] | localize }}
    <span nz-icon nzType="select" nzTheme="outline"></span>
  </button>

  <button
    *ngIf="isAdmin$ | async"
    [routerLink]="'/artists'"
    class="close-button blank"
  >
    X
  </button>

  <div class="tab info-tab" *ngIf="currentTab === ArtistTabs.Information">
    <form *ngIf="profileFormGroup" [formGroup]="profileFormGroup">
      <app-entity-info
        [formGroup]="profileFormGroup"
        [publicImage]="(selectedArtist$ | async)?.avatar"
        [entity]="selectedArtist$ | async"
        (nameChanged)="updateServer('name')"
      >
        <app-editable-radio
          [fieldTitle]="translations['visible']"
          formControlName="visible"
          [options]="yesNoChoices"
          (currentValueChange)="updateServer('visible')"
        ></app-editable-radio>
        <app-editable-selector
          [fieldTitle]="translations['activeExposition']"
          [options]="(expositionChoices$ | async)!"
          formControlName="activeExposition"
          (currentValueChange)="updateServer('activeExposition')"
        >
        </app-editable-selector>
        <app-editable-localized-text
          fieldTitle="Bio"
          [defaultRows]="10"
          formControlName="bio"
          (currentValueChange)="updateServer('bio')"
        >
        </app-editable-localized-text>

        <div class="divider"></div>

        <app-editable-selector-multi
          [fieldTitle]="translations['nationalities']"
          [options]="countriesOptions"
          formControlName="nationalities"
          (currentValueChange)="updateServer('nationalities')"
        >
        </app-editable-selector-multi>
        <app-editable-selector
          [fieldTitle]="translations['birthPlace']"
          [options]="countriesOptions"
          formControlName="birthPlace"
          (currentValueChange)="updateServer('birthPlace')"
        >
        </app-editable-selector>
        <app-editable-date
          formControlName="birthDate"
          [fieldTitle]="translations['birthDate']"
          (currentValueChange)="updateServer('birthDate')"
        >
        </app-editable-date>
      </app-entity-info>
    </form>
  </div>

  <div class="tab info-tab" *ngIf="currentTab === ArtistTabs.Expositions">
    <form *ngIf="expositionFormGroup" [formGroup]="expositionFormGroup">
      <div class="col card-body">
        <app-selector
          [options]="expositionChoices$ | async"
          [defaultSelection]="profileFormGroup.controls.activeExposition.value"
          (selectChanged)="onExpositionChanged($event)"
        ></app-selector>
        <app-entity-info
          *ngIf="currentExposition$ | async; let expo"
          [formGroup]="expositionFormGroup"
          [hasImage]="false"
          [entity]="expo"
          (nameChanged)="updateExposition()"
        >
          <app-editable-localized-text
            fieldTitle="Description"
            [defaultRows]="10"
            formControlName="description"
            (currentValueChange)="updateExposition()"
          >
          </app-editable-localized-text>
        </app-entity-info>
      </div>
    </form>
  </div>
</div>
