import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CoreModule } from 'src/app/shared/components/core.module';
import { DynamicFormItemsModule } from 'src/app/shared/components/dynamic-form-items/dynamic-form-items.module';
import { EntityInfoCardComponent } from 'src/app/shared/components/entity-info/entity-info.component';
import { NgZorroModule } from 'src/app/shared/components/ng-zorro.module';

@NgModule({
  declarations: [EntityInfoCardComponent],
  exports: [EntityInfoCardComponent],
  imports: [
    CommonModule,
    CoreModule,
    NgZorroModule,
    DynamicFormItemsModule,
    FormsModule,
    ReactiveFormsModule,
  ],
})
export class EntityInfoModule {}
