import { Component, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { LocalizedString, SupportedLocals } from '@apophenia/platform';
// import {
//   LocalizedString,
//   SupportedLocals,
// } from '@apophenia/shared/dist/localization';

@Component({
  selector: 'app-localized-string',
  templateUrl: './localized-string.component.html',
  styleUrls: ['./localized-string.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: LocalizedStringComponent,
    },
  ],
})
export class LocalizedStringComponent implements ControlValueAccessor {
  @Input() defaultRows = 5;
  localString: LocalizedString = {};

  locals = Object.keys(SupportedLocals) as SupportedLocals[];

  currentLocal = this.locals[0];

  onValueChanged(): void {
    const localString = this.locals.reduce((dict, local) => {
      if (this.localString[local]) {
        dict[local] = this.localString[local];
      }
      return dict;
    }, {} as LocalizedString);
    this.onChange(localString);
  }

  writeValue(localized: LocalizedString): void {
    this.localString = localized ?? {};
  }
  // eslint-disable-next-line unused-imports/no-unused-vars, @typescript-eslint/no-unused-vars, @typescript-eslint/no-empty-function
  onChange: (value: LocalizedString) => void = (_: LocalizedString) => {};
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onTouched: () => void = () => {};

  registerOnChange(fn: (value: LocalizedString) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }
}
