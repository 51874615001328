<form *ngIf="formGroup" [formGroup]="formGroup">
  <div class="tab info-tab" *ngIf="currentTab === 0">
    <app-entity-info
      [formGroup]="formGroup"
      [publicImage]="project?.thumbnail"
      [entity]="project"
      (nameChanged)="updateServer('name')"
    >
      <ng-container formGroupName="metadata">
        <app-editable-date
          formControlName="creationDate"
          [fieldTitle]="translations['createdAt']"
          (currentValueChange)="updateMetadata('creationDate')"
        >
        </app-editable-date>
        <app-editable-text
          [textStyle]="{ maxWidth: '300px' }"
          [fieldTitle]="translations['video']"
          formControlName="videoPreview"
          (currentValueChange)="updateMetadata('videoPreview')"
        >
        </app-editable-text>
        <app-editable-localized-text
          [fieldTitle]="'Description'"
          formControlName="description"
          (currentValueChange)="updateMetadata('description')"
        >
        </app-editable-localized-text>
      </ng-container>
    </app-entity-info>
  </div>

  <div class="tab file-tab" *ngIf="currentTab === 1">
    <app-platform-files
      [memories]="currentScenes"
      (memoryUpdated)="uploadFile($event, VRProjectManifestType.Scene)"
    ></app-platform-files>
  </div>
  <div class="tab file-tab" *ngIf="currentTab === 2">
    <app-platform-files
      [memories]="currentAudio"
      (memoryUpdated)="uploadFile($event, VRProjectManifestType.SoundBank)"
    ></app-platform-files>
  </div>
</form>
