import { Injectable } from '@angular/core';
import { Memory, ModulesMetadata } from '@apophenia/platform';
import { GenericModuleViewModel } from 'src/app/pages/generic-modules/generic-modules.models';
import { CommonDataService } from 'src/app/shared/services/_base-data.service';

@Injectable({ providedIn: 'root' })
export class GenericModulesService extends CommonDataService<
  ModulesMetadata,
  GenericModuleViewModel
> {
  protected override get baseURL(): string {
    return `/modules`;
  }

  protected override viewModel(
    entity: ModulesMetadata,
  ): GenericModuleViewModel {
    const thumbnail = (entity.manifests as Memory[])?.find((y) => y.isPublic);
    const scenes =
      (entity.manifests as Memory[])?.filter((y) => !y.isPublic) ?? [];
    return {
      ...entity,
      thumbnail,
      scenes,
    };
  }
}
