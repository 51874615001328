import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { catchError, firstValueFrom, Observable, shareReplay, tap } from 'rxjs';
import { NotificationsService } from 'src/app/shared/notifications/notifications.service';
import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class APIService {
  constructor(
    private http: HttpClient,
    private notification: NotificationsService,
    private spinner: NgxSpinnerService,
  ) {}

  async get<T = undefined>(
    endpoint: string,
    spinnerName?: string,
    success?: string,
    error?: string,
  ): Promise<T> {
    return await this.sendRequest(
      this.http.get<T>(this.formatURL(endpoint)),
      spinnerName,
      success,
      error,
    );
  }
  get$<T = undefined>(
    endpoint: string,
    spinnerName?: string,
    success?: string,
    error?: string,
  ): Observable<T> {
    return this.sendRequest$(
      this.http.get<T>(this.formatURL(endpoint)),
      spinnerName,
      success,
      error,
    );
  }

  async post<T = undefined>(
    endpoint: string,
    dto: unknown,
    spinnerName?: string,
    success?: string,
    error?: string,
  ): Promise<T> {
    return await this.sendRequest(
      this.http.post<T>(this.formatURL(endpoint), dto),
      spinnerName,
      success,
      error,
    );
  }

  async patch<T = undefined>(
    endpoint: string,
    dto: unknown,
    spinnerName?: string,
    success?: string,
    error?: string,
  ): Promise<T> {
    return await this.sendRequest(
      this.http.patch<T>(this.formatURL(endpoint), dto),
      spinnerName,
      success,
      error,
    );
  }

  async put<T = undefined>(
    endpoint: string,
    dto: unknown,
    spinnerName?: string,
    success?: string,
    error?: string,
  ): Promise<T> {
    return await this.sendRequest(
      this.http.put<T>(this.formatURL(endpoint), dto),
      spinnerName,
      success,
      error,
    );
  }

  async delete<T = undefined>(
    endpoint: string,
    spinnerName?: string,
    success?: string,
    error?: string,
  ): Promise<T> {
    return await this.sendRequest(
      this.http.delete<T>(this.formatURL(endpoint)),
      spinnerName,
      success,
      error,
    );
  }

  private async sendRequest<T = undefined>(
    request: Observable<T>,
    spinnerName?: string,
    success?: string,
    error?: string,
  ): Promise<T> {
    if (spinnerName) void this.spinner.show(spinnerName);
    try {
      const result = await firstValueFrom(request);
      if (success) this.notification.success(success);
      return result;
    } catch (e) {
      this.notification.error(
        error ?? 'Une erreur est survenu lors de la requête',
      );
      throw e;
    } finally {
      if (spinnerName) void this.spinner.hide(spinnerName);
    }
  }

  private sendRequest$<T = undefined>(
    request: Observable<T>,
    spinnerName?: string,
    success?: string,
    error?: string,
  ): Observable<T> {
    if (spinnerName) void this.spinner.show(spinnerName);
    return request.pipe(
      tap(() => {
        if (success) this.notification.success(success);
      }),
      catchError(() => {
        this.notification.error(
          error ?? 'Une erreur est survenu lors de la requête',
        );
        return [];
      }),
      tap(() => void this.spinner.hide(spinnerName)),
      shareReplay(1),
    );
  }

  private formatURL(endpoint: string): string {
    if (!endpoint.startsWith('/')) endpoint = `/${endpoint}`;
    return `${environment.apiRoot}${endpoint}`;
  }
}
