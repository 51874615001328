import {
  Component,
  EventEmitter,
  Input,
  Output,
  Pipe,
  PipeTransform,
} from '@angular/core';
import { Memory, LocalizedDevices, DeviceTypes } from '@apophenia/platform';
import { TabsModuleConfigs } from 'src/app/shared/components/multiple-tabs/multiple-tabs.component';
import { MemoryFileUpdateEvent } from 'src/app/shared/components/platform-files/platform-files-info/platform-files-info.component';
import { LocalizedDict } from 'src/app/shared/localization/lozalize.models';

const defaultTabsConfigs: TabsModuleConfigs = [
  {
    label: LocalizedDevices[DeviceTypes.Quest],
  },
  {
    label: LocalizedDevices[DeviceTypes.PC],
    disabled: false,
  },
  {
    label: LocalizedDevices[DeviceTypes.Webgl],
    disabled: true,
  },
  {
    label: LocalizedDevices[DeviceTypes.Android],
    disabled: true,
  },
];

@Component({
  selector: 'app-platform-files',
  templateUrl: './platform-files.component.html',
  styleUrls: ['./platform-files.component.scss'],
})
export class PlatformFilesComponent {
  @Input() memories!: Memory[];
  @Input() isUniversal = false;
  @Input() hasPreview = false;
  @Output() memoryUpdated = new EventEmitter<MemoryFileUpdateEvent>();

  localizedDevices = LocalizedDevices;

  currentDevice = 0;
  tabsConfig: TabsModuleConfigs = defaultTabsConfigs;
  deviceMap: Record<number, DeviceTypes> = {
    0: DeviceTypes.Quest,
    1: DeviceTypes.PC,
    2: DeviceTypes.Webgl,
    3: DeviceTypes.Android,
  };

  translations: LocalizedDict = {
    historic: { EN: 'History', FR: 'Historique' },
  };
}

@Pipe({
  name: 'getMemory',
})
export class GetMemoryPipe implements PipeTransform {
  transform(
    value: Memory[],
    device: DeviceTypes,
    group?: number,
  ): Memory | undefined {
    return (
      value.find((x) => x.device == device && (!group || group == x.groupID)) ??
      ({ groupID: group, device: device } as Memory)
    );
  }
}
