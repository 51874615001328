import { InjectionToken } from '@angular/core';
import { LocalizedString } from '@apophenia/platform';

export const LocalizationInjectedDataToken = new InjectionToken(
  'Current language data injected into the current route',
);
export const LocalizationSharedInjectedDataToken = new InjectionToken(
  'Shared application language data injected into the current route',
);

export type LocalizedDict<K extends string = string> = Record<
  K,
  LocalizedString | string
>;
