/* eslint-disable unused-imports/no-unused-vars */
import { Injectable, Injector, Type } from '@angular/core';
import {
  KeyOfType,
  CoreEntity,
  Memory,
  MemoryEncodingRequestDTO,
} from '@apophenia/platform';
import { BehaviorSubject, map, Observable, share } from 'rxjs';
import { MAIN_SPINNER_NAME } from 'src/app/app.component';
import { NotificationsService } from 'src/app/shared/notifications/notifications.service';
import { APIService } from 'src/app/shared/services/api.service';
import { AuthService } from 'src/app/shared/services/auth.service';
import {
  FileUploadRequest,
  MemoryService,
} from 'src/app/shared/services/memory.service';
import { mergeDeep } from 'src/app/shared/utils/merge-deep';

export interface DataStore<TViewModel, TUpdateDTO = Partial<TViewModel>> {
  loaded: boolean;
  selectAll: () => Observable<TViewModel[]>;
  selectById: (id: string) => Observable<TViewModel>;

  createOne: (dto: TUpdateDTO) => Promise<void>;
  updateOne: (id: string, dto: TUpdateDTO) => Promise<void>;
  deleteOne: (id: string) => Promise<void>;
  reloadData: () => Promise<void>;
}

export interface DataState<TEntity, TViewModel> {
  ids: string[];
  entities: Record<string, TViewModel>;
  raw: Record<string, TEntity>;
}

export interface BaseMemoryEncodingRequest {
  dto: Partial<MemoryEncodingRequestDTO>;
  fileUpload: FileUploadRequest;
}

@Injectable({
  providedIn: 'root',
})
export abstract class CommonDataService<
  TEntity extends CoreEntity = CoreEntity,
  TViewModel = TEntity,
  TUpdateDTO = unknown,
> implements DataStore<TViewModel, TUpdateDTO>
{
  loaded = false;
  protected state$ = new BehaviorSubject<DataState<TEntity, TViewModel>>({
    ids: [],
    entities: {},
    raw: {},
  });

  protected message: NotificationsService;
  protected apiService: APIService;
  protected authService: AuthService;
  protected memoryService: MemoryService;

  private dependencies: Record<string, DataStore<unknown>> = {};

  constructor(protected injector: Injector) {
    this.message = injector.get(NotificationsService);
    this.apiService = injector.get(APIService);
    this.memoryService = injector.get(MemoryService);
    this.authService = injector.get(AuthService);

    this.dependencies = this.loadDependencies.reduce((record, dep) => {
      record[dep.name] = injector.get(dep);
      return record;
    }, {} as Record<string, DataStore<unknown>>);
  }

  get state(): DataState<TEntity, TViewModel> {
    return this.state$.value;
  }

  protected get primaryID(): KeyOfType<TEntity, string> {
    return 'id' as KeyOfType<TEntity, string>;
  }
  protected get sortBy(): string {
    return 'id';
  }

  protected get loadDependencies(): Type<DataStore<unknown>>[] {
    return [];
  }

  protected abstract get baseURL(): string;

  selectAll(): Observable<TViewModel[]> {
    return this.state$.pipe(
      map((state) => state.ids.map((id) => state.entities[id])),
      share(),
    );
  }

  selectById(id: string): Observable<TViewModel> {
    return this.state$.pipe(map((state) => state.entities[id]));
  }

  async updateOne(
    id: string,
    dto: TUpdateDTO,
    baseURL?: string,
  ): Promise<void> {
    const result = await this.apiService.patch<Partial<TEntity>>(
      `${baseURL ?? this.baseURL}/${id}`,
      dto,
      MAIN_SPINNER_NAME,
    );
    this.updateOneEntity(id, result);
  }

  async createOne(dto: TUpdateDTO, baseURL?: string): Promise<void> {
    const result = await this.apiService.post<TEntity>(
      baseURL ?? this.baseURL,
      dto,
      MAIN_SPINNER_NAME,
    );
    this.addOneEntity(result);
  }

  async deleteOne(id: string, baseURL?: string): Promise<void> {
    await this.apiService.delete<TEntity>(
      `${baseURL ?? this.baseURL}/${id}`,
      MAIN_SPINNER_NAME,
    );
    this.removeOneEntity(id);
  }

  async reloadData(): Promise<void> {
    for (const service of Object.keys(this.dependencies)) {
      if (!this.dependencies[service].loaded) {
        await this.dependencies[service].reloadData();
      }
    }
    const entities =
      (await this.apiService.get<TEntity[]>(
        `${this.baseURL}?sort=${this.sortBy}`,
        MAIN_SPINNER_NAME,
      )) ?? [];
    const newState = entities.reduce<DataState<TEntity, TViewModel>>(
      (state, e) => {
        const id = e[this.primaryID] as unknown as string;
        state.ids.push(id);
        state.raw[id] = e;
        state.entities[id] = this.viewModel(e);
        return state;
      },
      { ids: [], entities: {}, raw: {} },
    );
    this.state$.next(newState);
    this.loaded = true;
  }

  async uploadFile(
    id: string,
    sourceFile: BaseMemoryEncodingRequest,
    key: KeyOfType<TEntity, Memory>,
  ): Promise<void> {
    const newFile = await this.memoryService.uploadNewFile(
      this.getFileURL(id),
      sourceFile.dto,
      sourceFile.fileUpload,
    );
    this.replaceOrUpdateFile(id, newFile, key);
  }

  async removeFile(
    id: string,
    fileID: string,
    key: KeyOfType<TEntity, Memory>,
  ): Promise<void> {
    await this.apiService.delete<Memory>(this.getFileURL(id, fileID));
    const files =
      (this.getRawEntity(id)[key] as unknown as Memory[])?.filter(
        (x) => x.id != fileID,
      ) ?? [];

    this.updateOneEntity(id, { [key]: files } as unknown as Partial<TEntity>);
  }

  protected getEntity(id: string): TViewModel {
    return this.state.entities[id];
  }

  protected getRawEntity(id: string): TEntity {
    return this.state.raw[id];
  }

  protected getFileURL(id: string, fileID?: string): string {
    return `${this.baseURL}/${id}/files${fileID ? '/' + fileID : ''}`;
  }

  protected updateOneEntity(id: string, entity: Partial<TEntity>): void {
    const newState = this.state$.value;
    if (!newState.ids.includes(id)) {
      throw new Error(`${id} not found in store`);
    }
    newState.raw[id] = mergeDeep(newState.raw[id], entity);
    newState.entities[id] = this.viewModel({ ...newState.raw[id] });
    this.state$.next(newState);
  }
  protected addOneEntity(entity: TEntity): void {
    const newState = this.state$.value;
    const id = entity[this.primaryID] as unknown as string;
    newState.raw[id] = entity;
    newState.entities[id] = this.viewModel({
      ...newState.raw[id],
    });
    this.state$.next(newState);
  }
  protected removeOneEntity(id: string): void {
    const newState = this.state$.value;
    delete newState.raw[id];
    delete newState.entities[id];
    newState.ids = newState.ids.filter((x) => x != id);
    this.state$.next(newState);
  }

  protected replaceOrUpdateFile(
    id: string,
    newFile: Memory,
    key: KeyOfType<TEntity, Memory>,
  ): void {
    const files = (this.getRawEntity(id)[key] ?? []) as Memory[];
    const index = files?.findIndex((x) => x.id == newFile.id) ?? -1;
    if (index == -1) {
      files.push(newFile);
    } else {
      files[index] = newFile;
    }
    this.updateOneEntity(id, { [key]: files } as unknown as Partial<TEntity>);
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  protected viewModel(entity: TEntity): TViewModel {
    return entity as unknown as TViewModel;
  }

  protected getDependency<T extends DataStore<unknown>>(service: Type<T>): T {
    return this.dependencies[service.name] as T;
  }
}
