import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  Pipe,
  PipeTransform,
} from '@angular/core';
import {
  Memory,
  ArtistExpositionGroups,
  ArtistExpositionMemoryGroup,
} from '@apophenia/platform';
import { Observable } from 'rxjs';
import { ExpositionContentViewModel } from 'src/app/pages/artists/artists.models';
import {
  ArtistContentMemoryDTO,
  ArtistsService,
} from 'src/app/pages/artists/artists.service';
import { TabsModuleConfigs } from 'src/app/shared/components/multiple-tabs/multiple-tabs.component';
import { MemoryFileUpdateEvent } from 'src/app/shared/components/platform-files/platform-files-info/platform-files-info.component';
import { LocalizedDict } from 'src/app/shared/localization/lozalize.models';
import { FileUploadRequest } from 'src/app/shared/services/memory.service';

const localizedArtistFileTypes: LocalizedDict = {
  [ArtistExpositionGroups.Preview]: { EN: 'Preview', FR: 'Aperçu' },
  [ArtistExpositionGroups.Source]: { EN: 'Source', FR: 'Source' },
  [ArtistExpositionGroups.Optimized]: { EN: 'Optimized', FR: 'Optimisé' },
};

const defaultTabsConfigs: TabsModuleConfigs = [
  {
    label: localizedArtistFileTypes[ArtistExpositionGroups.Source],
  },
  {
    label: localizedArtistFileTypes[ArtistExpositionGroups.Optimized],
  },
  {
    label: localizedArtistFileTypes[ArtistExpositionGroups.Preview],
  },
];

@Component({
  selector: 'app-artists-files',
  templateUrl: './artists-files.component.html',
  styleUrls: ['./artists-files.component.scss'],
})
export class ArtistFilesComponent implements OnInit {
  @Input() artistID!: string;
  @Input() expoID!: string;
  @Input() contentID!: string;
  @Input() isUniversal = false;
  @Input() hasPreview = false;
  @Output() memoryUpdated = new EventEmitter<MemoryFileUpdateEvent>();

  ArtistExpositionMemoryGroup = ArtistExpositionMemoryGroup;

  currentDevice = 0;
  tabsConfig: TabsModuleConfigs = defaultTabsConfigs;
  typeMap: Record<number, ArtistExpositionGroups> = {
    0: ArtistExpositionGroups.Source,
    1: ArtistExpositionGroups.Optimized,
    2: ArtistExpositionGroups.Preview,
  };

  content$!: Observable<ExpositionContentViewModel>;

  translations: LocalizedDict = {
    historic: { EN: 'History', FR: 'Historique' },
    0: {
      EN: 'Source files associated with the artwork. These files will only be served on the platform if an optimized version is not uploaded.',
      FR: "Fichiers source de l'oeuvre. Ces fichiers ne seront pas visible sur la plateforme si une version optimisée est téléversée.",
    },
    1: {
      EN: 'Optimized files adapted for the platform. You can use this variant if you want to serve a lighter or optimized version of your source code. <br/>This is generally a good idea if, for example, your source files require higher performances than a VR headset can handle.',
      FR: 'Fichiers optimisé pour la plateforme. Vous pouvez utiliser cette variante pour servir un fichier plus léger ou optimisé aux visiteurs. Utiliser une version "optimisé" est recommandé si les fichiers sources demandes plus de performances que ce qu\'un casque VR peut offrir',
    },
    2: {
      EN: 'The preview is a small thumbnail that is served on web pages or menus. <br/>This is useful to quickly show the artwork without loading the full image, especially for 3d models.<br/>The usage of a thumbnail is required to be visible on the platform.',
      FR: "L'aperçu est une petite image utilisée pour une diffusion web ou pour les menus. <br/>L'aperçu permet de visualiser rapidement l'oeuvre sans devoir télécharger l'entièreté, surtout dans le cas d'un objet 3D. L'utilisation d'un aperçu est obligatoire pour être visible sur la plateforme.",
    },
  };

  constructor(private artistService: ArtistsService) {}

  ngOnInit(): void {
    this.content$ = this.artistService.selectContent(
      this.artistID,
      this.expoID,
      this.contentID,
    );
  }

  async uploadFile(
    ev: MemoryFileUpdateEvent,
    contentID: string,
  ): Promise<void> {
    const fileUpload: FileUploadRequest = {
      file: ev.file,
      uploadTag: 'artist-content',
    };
    const dto: ArtistContentMemoryDTO = {
      id: ev.memory?.id,
      group:
        (ev.memory?.group as ArtistExpositionGroups) ??
        ArtistExpositionGroups.Source,
    };
    await this.artistService.createOrUpdateContent(
      this.artistID,
      this.expoID,
      { id: contentID },
      { dto, fileUpload },
    );
  }
}

@Pipe({
  name: 'getMemory',
})
export class GetArtistMemoryPipe implements PipeTransform {
  transform(
    content: ExpositionContentViewModel,
    type: ArtistExpositionGroups,
  ): Memory | undefined {
    let memory: Memory | undefined = undefined;
    switch (type) {
      case ArtistExpositionGroups.Source:
        memory = content.source;
        break;
      case ArtistExpositionGroups.Optimized:
        memory = content.optimized;
        break;
      case ArtistExpositionGroups.Preview:
        memory = content.preview;
        break;
    }
    return memory ?? ({ group: type } as Memory);
  }
}
