import { LocalizedString } from '../../localization';

export interface UpdateExpositionDTO {
  id?: string;
  name: string;
  description?: LocalizedString;
}

export interface UpdateArtistExpositionDTO {
  exposition: UpdateExpositionDTO;
}
