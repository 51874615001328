import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ClipboardModule } from 'ngx-clipboard';
import { CoreModule } from 'src/app/shared/components/core.module';
import { NgZorroModule } from 'src/app/shared/components/ng-zorro.module';
import { PlatformFilesInfoComponent } from 'src/app/shared/components/platform-files/platform-files-info/platform-files-info.component';
import {
  GetMemoryPipe,
  PlatformFilesComponent,
} from 'src/app/shared/components/platform-files/platform-files/platform-files.component';
import { LocalizeModule } from 'src/app/shared/localization/localize.module';

@NgModule({
  declarations: [
    PlatformFilesInfoComponent,
    PlatformFilesComponent,
    GetMemoryPipe,
  ],
  imports: [
    CommonModule,
    ClipboardModule,
    LocalizeModule,
    NgZorroModule,
    CoreModule,
  ],
  exports: [PlatformFilesInfoComponent, PlatformFilesComponent],
})
export class PlatformFileModule {}
