import {
  IsArray,
  IsBoolean,
  IsOptional,
  IsString,
  Validate,
} from 'class-validator';
import { IsRecord } from '../../entity-metadata';
import { ProjectTemplate } from '../templates';
import { CreateProjectDTO } from './create-project.dto';
// export interface UpdateProjectManifest
//   extends Partial<Omit<CreateProjectManifestDTO, 'manifestType'>> {
//   manifestType: ProjectManifestType;
// }

export class UpdateProjectDTO<TTemplate = ProjectTemplate>
  implements Partial<CreateProjectDTO<TTemplate>>
{
  @IsString()
  @IsOptional()
  name?: string;

  @IsString()
  @IsOptional()
  organismID?: string;
  @Validate(IsRecord)
  @IsOptional()
  metadata?: TTemplate;

  @IsString()
  @IsOptional()
  platformVersion?: string;
  @IsBoolean()
  @IsOptional()
  isDev?: boolean;
  @IsBoolean()
  @IsOptional()
  isPrivate?: boolean;
  @IsBoolean()
  @IsOptional()
  isFree?: boolean;
  @IsOptional()
  @IsString({ each: true })
  @IsArray()
  categories?: string[];
}
