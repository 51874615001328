<nz-select
  *ngIf="options"
  [ngModel]="currentSelection"
  (ngModelChange)="onSelect($event)"
>
  <nz-option
    *ngFor="let opt of options"
    [nzValue]="opt.value"
    [nzLabel]="(opt.label | localize) ?? ''"
  >
  </nz-option>
</nz-select>
