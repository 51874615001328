export enum MemoryDimensions {
  Private = 'Private',
  Public = 'Public',
  API = 'API',
}

export type MemoryLimits = Record<
  keyof typeof MemoryDimensions,
  number | undefined | null
>;

export interface MemoryTracking {
  transfered: Record<keyof Omit<typeof MemoryDimensions, 'Public'>, number>;
  count: Record<keyof Omit<typeof MemoryDimensions, 'Public'>, number>;
}

export const defaultMemoryTracking: MemoryTracking = {
  transfered: { Private: 0, API: 0 },
  count: { Private: 0, API: 0 },
};
