import { APIAccesses } from './auth/api-accesses.entity';
import { AuthCredentials } from './auth/auth-credentials.entity';

export const ARCVR_CLIENT_HEADER = 'Archivvr-Client';
export type GrantTypes = 'dashboard' | 'platform';

interface LoginRequest {
  grantType: GrantTypes;
  email: string;
  password: string;
}

export interface DashboardLoginDTO extends LoginRequest {
  grantType: 'dashboard';
}
export interface PlatformLoginDTO extends LoginRequest {
  grantType: 'platform';
  device_id: string;
}
export type LoginDTO = DashboardLoginDTO | PlatformLoginDTO;

/*********************************************************************/
/*********************************************************************/

type BasePayload = Pick<AuthCredentials, 'email' | 'username'> &
  Pick<APIAccesses, 'isAdmin' | 'organisms' | 'partners'> & {
    exp: number;
    iat: number;
  };

export interface ApiTokenPayload extends BasePayload {
  id: string;
  artist?: string;
  grantType: GrantTypes;
}

export interface ApiRefreshTokenPayload {
  id: string;
  device: string;
  seed: string;
  grantType: GrantTypes;
}
/*********************************************************************/
/*********************************************************************/

export interface RefreshTokensDTO {
  grantType: GrantTypes;
  refresh_token: string;
}

/*********************************************************************/
/*********************************************************************/

export interface SignupDTO {
  email: string;
  password: string;
  invitationCode?: string;
}

/*********************************************************************/
/*********************************************************************/

interface LogoutRequest {
  grantType: GrantTypes;
}
export interface DashboardLogoutDTO extends LogoutRequest {
  grantType: 'dashboard';
}
export interface PlatformLogoutDTO extends LogoutRequest {
  grantType: 'platform';
  device_id?: string;
}
export type LogoutDTO = DashboardLogoutDTO | PlatformLogoutDTO;

/*********************************************************************/
/*********************************************************************/

export interface PasswordResetDTO {
  email: string;
  oldPassword: string;
  newPassword: string;
}

export interface ChangeLoginsDTO {
  userID: string;
  password: string;
  email?: string;
  username?: string;
}
