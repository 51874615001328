import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { LocalizedString } from '@apophenia/platform';

export interface SelectOption<TValue = any> {
  value: TValue;
  label: string | LocalizedString;
  id?: string;
}

@Component({
  selector: 'app-selector',
  templateUrl: './selector.component.html',
  styleUrls: ['./selector.component.scss'],
})
export class SelectorComponent implements OnInit {
  @Input() options?: SelectOption[] | null;
  @Input() defaultSelection?: any;
  @Output() selectChanged = new EventEmitter<any>();

  currentSelection?: unknown;
  currentTab?: number;

  ngOnInit(): void {
    this.currentSelection = this.defaultSelection;
  }

  onSelect(ev: unknown): void {
    this.currentSelection = ev;
    this.selectChanged.emit(ev);
  }
}
