import { Injectable } from '@angular/core';
import {
  Memory,
  Organism,
  OrganismFileTypes,
  OrganismFilesGroups,
} from '@apophenia/platform';
import { OrganismViewModel } from 'src/app/pages/organisms/organisms.models';
import { CommonDataService } from 'src/app/shared/services/_base-data.service';

export const ORGANISM_SPINNER = 'organisms-main-spinner';

@Injectable({ providedIn: 'root' })
export class OrganismsService extends CommonDataService<
  Organism,
  OrganismViewModel
> {
  protected override get baseURL(): string {
    return '/organisms';
  }

  protected override get sortBy(): string {
    return 'name';
  }

  protected override viewModel(entity: Organism): OrganismViewModel {
    const existing = (this.getEntity(entity.id)?.files ?? []) as Memory[];
    const files =
      typeof entity?.files == 'string'
        ? existing
        : (entity?.files as Memory[]) ?? [];
    return {
      ...entity,
      files,
      hub:
        files.filter(
          (x) => x.groupID == OrganismFilesGroups[OrganismFileTypes.Hub],
        ) ?? [],
      thumbnail: files.find(
        (x) => x.groupID == OrganismFilesGroups[OrganismFileTypes.Visual],
      ),
      documents: files.filter(
        (x) => x.groupID == OrganismFilesGroups[OrganismFileTypes.Document],
      ),
    };
  }
}
