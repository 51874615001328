<div class="card project-card">
  <app-tabs
    *ngIf="projectTabsConfigs"
    [tabs]="projectTabsConfigs"
    [(currentTab)]="currentTab"
  ></app-tabs>
  <button [routerLink]="'/projects'" class="close-button blank">X</button>
  <app-vr-projects-card
    *ngIf="currentType === 'VR'"
    [project]="selectedProject$ | async"
    [currentTab]="currentTab"
  ></app-vr-projects-card>
  <!--<form *ngIf="formGroup" [formGroup]="formGroup">
    <div class="tab info-tab" *ngIf="currentTab === 0">
     <app-entity-info
        [formGroup]="formGroup"
        [publicImage]="(selectedProject$ | async)?.thumbnail"
        [entity]="selectedProject$ | async"
      > -->
  <!-- <app-editable-date
          formControlName="creationDate"
          [fieldTitle]="translations['createdAt']"
        >
        </app-editable-date>
        <app-editable-text
          [textStyle]="{ maxWidth: '300px' }"
          [fieldTitle]="translations['video']"
          formControlName="videoPreview"
        >
        </app-editable-text>
        <app-editable-localized-text
          [fieldTitle]="'Description'"
          formControlName="localizedDescriptions"
        >
        </app-editable-localized-text> -->
  <!-- </app-entity-info>
    </div> -->
  <!--
    <div class="tab file-tab" *ngIf="currentTab === ProjectTabs.Scene">
      <app-platform-files
        [memories]="(selectedProject$ | async)?.scenes ?? []"
      ></app-platform-files>
    </div>
    <div class="tab file-tab" *ngIf="currentTab === ProjectTabs.Audio">
      <app-platform-files
        [memories]="(selectedProject$ | async)?.soundbanks ?? []"
      ></app-platform-files>
    </div>
  </form>-->
</div>
