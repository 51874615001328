import { Component, EventEmitter, Input, Output } from '@angular/core';

export interface FileEvent {
  files: File[];
  filterTag?: string;
}
@Component({
  selector: 'app-upload-button',
  templateUrl: './drag-and-drop-button.component.html',
  styleUrls: ['./drag-and-drop-button.component.scss'],
})
export class DragAndDropButtonComponent {
  @Output() fileEvent: EventEmitter<FileEvent> = new EventEmitter<FileEvent>();

  @Input() public filterTag?: string;
  @Input() public buttonClass?: string = 'primary';
  @Input() public disabled = false;
  @Input() public buttonWidth: string | 'auto' = '100px';
  @Input() public fileType = '*';

  @Input() textContent = '';
  @Input() iconContent?: string = undefined;

  onFilesSelected(
    ev: Event & { target: (EventTarget & { files?: File[] }) | null },
  ): void {
    if (ev.type == 'change' && ev.target?.files && ev.target.files.length > 0) {
      this.fileEvent.emit({ files: ev.target.files });
    }
  }
}
