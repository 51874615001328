import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { catchError } from 'rxjs/operators';
import { AuthService } from 'src/app/shared/services/auth.service';

@Injectable({
  providedIn: 'root',
})
export class AuthInterceptor implements HttpInterceptor {
  token?: string;

  constructor(private auth: AuthService) {
    this.auth.token$.subscribe((t) => {
      this.token = t;
    });
  }

  intercept(
    req: HttpRequest<unknown>,
    next: HttpHandler,
  ): Observable<HttpEvent<unknown>> {
    if (
      req.url.includes(environment.apiRoot) ||
      req.url.includes(`${environment.auth_domain}/users/info`)
    ) {
      const tokenReq = req.clone({
        setHeaders: {
          Authorization: `Bearer ${this.auth.token$.value as string}`,
        },
      });
      return next.handle(tokenReq).pipe(
        catchError((error: Error) => {
          if (error instanceof HttpErrorResponse && error.status === 401) {
            this.auth.logout();
            return throwError(() => new Error(error.message));
          }
          return throwError(() => error);
        }),
      );
    }

    return next.handle(req);
  }
}
