import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Observable, map } from 'rxjs';
import { OrganismViewModel } from 'src/app/pages/organisms/organisms.models';
import { OrganismsService } from 'src/app/pages/organisms/organisms.service';
import { SelectOption } from 'src/app/shared/components/selector/selector.component';

@Component({
  selector: 'app-organisms-selector',
  templateUrl: './organisms-selector.component.html',
  styleUrls: ['./organisms-selector.component.scss'],
})
export class OrganismSelectorComponent {
  @Input() selectedOrganism?: OrganismViewModel | null;
  @Output() selectedOrganismChange = new EventEmitter<OrganismViewModel>();

  orgsOptions$: Observable<SelectOption<OrganismViewModel>[]>;

  constructor(private organismService: OrganismsService) {
    this.orgsOptions$ = this.organismService
      .selectAll()
      .pipe(map((x) => x.map((org) => ({ label: org.name, value: org }))));
  }

  onSelect(org: OrganismViewModel): void {
    this.selectedOrganism = org;
    this.selectedOrganismChange.emit(this.selectedOrganism);
  }
}
