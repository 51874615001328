import { Component, Input, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { EditableFormItem } from 'src/app/shared/components/dynamic-form-items/_editable-form';

@Component({
  selector: 'app-editable-text',
  templateUrl: './editable-text.component.html',
  styleUrls: ['./editable-text.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => EditableTextComponent),
      multi: true,
    },
  ],
})
export class EditableTextComponent extends EditableFormItem<string> {
  @Input() textStyle?: Partial<CSSStyleDeclaration>;
  @Input() editingStyle?: Partial<CSSStyleDeclaration>;

  override checkHasChanged(oldValue?: string, newValue?: string): boolean {
    return oldValue != newValue;
  }
}
