import { Component } from '@angular/core';

import { ModulesMetadata } from '@apophenia/platform';
import { Observable, map } from 'rxjs';
import { GenericModulesService } from 'src/app/pages/generic-modules/generic-modules.service';
import { MISSING_PICTURE_IMAGE } from 'src/app/shared/models/assets.models';

export type ModuleListItem = Pick<ModulesMetadata, 'id' | 'name'> & {
  img: string;
  url: string;
};

@Component({
  selector: 'app-modules-list',
  templateUrl: './modules-list.component.html',
  styleUrls: ['./modules-list.component.scss'],
})
export class GenericModulesListComponent {
  modules$: Observable<ModuleListItem[]>;

  constructor(private modulesService: GenericModulesService) {
    this.modules$ = this.modulesService.selectAll().pipe(
      map((modules) =>
        modules.map((m) => ({
          id: m.id,
          name: m.name,
          img: m.thumbnail?.publicURI ?? MISSING_PICTURE_IMAGE,
          url: `/modules/${m.id}`,
        })),
      ),
    );
  }
}
