<div class="sidebar">
  <div class="archivvr">
    <a href="https://www.archivvr.com" target="_blank"> ARCHIV VR </a>
  </div>
  <div class="nav-container">
    <div class="top-container">
      <div
        class="nav-item {{ nav.activeClass }}"
        [routerLink]="nav.path"
        *ngFor="let nav of topNav$ | async"
      >
        <i nz-icon nzType="{{ nav.icon }}" nzTheme="outline"></i>
        <a>{{ nav.label | localize }}</a>
      </div>
    </div>
    <div class="bottom-container">
      <div
        class="nav-item {{ nav.activeClass }}"
        [routerLink]="nav.path"
        *ngFor="let nav of bottomNav$ | async"
      >
        <i nz-icon nzType="{{ nav.icon }}" nzTheme="outline"></i>
        <a>{{ nav.label | localize }}</a>
      </div>
    </div>
  </div>
</div>
