<form
  *ngIf="formGroup && (content$ | async)"
  [formGroup]="formGroup"
  (ngSubmit)="updateServer()"
>
  <app-editable-radio
    [fieldTitle]="translations['hasCartel']"
    formControlName="hasCartel"
    [options]="yesNoChoices"
  ></app-editable-radio>

  <div class="form-control" *ngIf="formGroup.controls.hasCartel.value">
    <span class="label"> {{ translations['width'] | localize }}</span>
    <nz-slider
      [nzStep]="0.01"
      [nzMin]="0.01"
      [nzMax]="0.5"
      formControlName="cartelWidth"
      (ngModelChange)="updatePreview()"
    >
    </nz-slider>
    <span class="label"> {{ translations['height'] | localize }}</span>
    <nz-slider
      [nzStep]="0.01"
      [nzMin]="0.01"
      [nzMax]="0.5"
      formControlName="cartelHeight"
      (ngModelChange)="updatePreview()"
    >
    </nz-slider>
  </div>

  <div #cartelItem class="cartel-preview" *ngIf="content$ | async; let content">
    <span>
      <b>{{ artistName }} </b>
    </span>
    <br />
    <br />
    <span class="cartel-title">
      <b>
        <i
          innerHTML="{{ content.name }}, {{
            content.createdAt | date : 'YYYY'
          }}"
        >
        </i>
      </b>
    </span>
    <br />
    <ng-container *ngIf="content.medium">
      <span>
        {{ content.medium }}
      </span>
      <br />
    </ng-container>
    <span>
      {{ formGroup.controls.width.value }}m x
      {{ formGroup.controls.height.value }}m x
      {{ formGroup.controls.depth.value }}m
    </span>
    <br />
    <br />
    <span *ngIf="content.description.EN || content.description.FR">
      {{ content.description | localize }}
    </span>
  </div>
  <button type="submit" class="primary">
    {{ translations['submit'] | localize }}
  </button>
</form>

<!--    <div class="cartel-preview-container">
        <div class="dimension-input">
          <nz-form-item>
            <nz-form-label
              [nzSpan]="24"
              class="card-subtitle text-muted small-input"
            >
              Largeur Cartel
            </nz-form-label>
            <nz-form-control [nzSpan]="24">
              <nz-slider
                [nzStep]="0.01"
                [nzMin]="0.01"
                [nzMax]="0.5"
                formControlName="cartelWidth"
              >
              </nz-slider>
            </nz-form-control>
          </nz-form-item>

          <nz-form-item
            *ngIf="contentTypeCategory !== ExpositionContentTabs.Audio"
          >
            <nz-form-label
              nzFor="content-image"
              [nzSpan]="24"
              class="card-subtitle text-muted small-input"
            >
              Hauteur Cartel
            </nz-form-label>
            <nz-form-control [nzSpan]="24">
              <nz-slider
                [nzStep]="0.01"
                [nzMin]="0.01"
                [nzMax]="0.5"
                formControlName="cartelHeight"
              >
              </nz-slider>
            </nz-form-control>
          </nz-form-item>
        </div>
      </div> -->
