import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { RouteAccessLevel, RouteInfo } from 'src/app/layout/routes';
import { GenericModulesCardComponent } from 'src/app/pages/generic-modules/generic-modules-card/modules-card.component';
import { GenericModulesListComponent } from 'src/app/pages/generic-modules/generic-modules-list/modules-list.component';
import { GenericModulesService } from 'src/app/pages/generic-modules/generic-modules.service';
import { CoreModule } from 'src/app/shared/components/core.module';
import { DynamicFormItemsModule } from 'src/app/shared/components/dynamic-form-items/dynamic-form-items.module';
import { EntityInfoModule } from 'src/app/shared/components/entity-info/entity-info.module';
import { PlatformFileModule } from 'src/app/shared/components/platform-files/platform-files.module';
import { LocalizeModule } from 'src/app/shared/localization/localize.module';
import { createServiceResolver } from 'src/app/shared/utils/resolve-dataservice';

@NgModule({
  declarations: [GenericModulesListComponent, GenericModulesCardComponent],
  imports: [
    CommonModule,
    CoreModule,
    FormsModule,
    ReactiveFormsModule,
    DynamicFormItemsModule,
    EntityInfoModule,
    LocalizeModule,
    PlatformFileModule,
    RouterModule.forChild([
      { path: '', component: GenericModulesListComponent },
      { path: ':id', component: GenericModulesCardComponent },
    ]),
  ],
})
export class GenericModulesModule {
  static get RouteInfo(): RouteInfo[] {
    return [
      {
        path: 'modules',
        loadChildren: () =>
          import('./generic-modules.module').then(
            (m) => m.GenericModulesModule,
          ),
        resolve: {
          modules: createServiceResolver(GenericModulesService),
        },
        label: 'Modules',
        icon: 'partition',
        visible: true,
        requiredAccess: RouteAccessLevel.Admin,
      },
    ];
  }
}
