<i
  nz-icon
  nzType="info-circle"
  nzTheme="outline"
  nzTooltipColor="#ffffff"
  [nzTooltipVisible]="tooltipVisible"
  [nzTooltipTitle]="toolTipTemplate"
  nzTooltipPlacement="right"
  nz-i
  nz-tooltip
  [nzTheme]="'twotone'"
  [nzTwotoneColor]="'#b9b9b9'"
  (mouseover)="tooltipVisible = true"
>
</i>
<ng-template #toolTipTemplate>
  <div class="tooltip-container">
    <h3>{{ header }}</h3>
    <p [innerHTML]="description"></p>
    <a *ngIf="learnMoreLink" [href]="learnMoreLink" target="_blank">
      Learn More
    </a>
    <button nz-button nzType="primary" (click)="hideTooltip()">Got it</button>
  </div>
</ng-template>
