import { LocalizedString } from './localization/localized-string';

export enum DeviceTypes {
  Universal = 'Universal',
  Quest = 'Quest',
  PC = 'PC',
  Android = 'Android',
  Webgl = 'Webgl',
}

export const LocalizedDevices: Record<DeviceTypes, LocalizedString> = {
  [DeviceTypes.Android]: { EN: 'Android', FR: 'Android' },
  [DeviceTypes.PC]: { EN: 'Windows', FR: 'Windows' },
  [DeviceTypes.Quest]: { EN: 'Meta Quest', FR: 'Meta Quest' },
  [DeviceTypes.Webgl]: { EN: 'Web', FR: 'Web' },
  [DeviceTypes.Universal]: { EN: 'Other/Universal', FR: 'Autre/Universel' },
};

export enum PlatformType {
  Client = 'Client',
  Main = 'Main',
}

export class CoreEntity {
  id: string;
  createdAt: Date;
  updatedAt: Date;
  deletedAt: Date;
}

export type APP_ENV = 'local' | 'development' | 'production';
