import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Memory, LocalizedDevices } from '@apophenia/platform';
import { LocalizedDict } from 'src/app/shared/localization/lozalize.models';
import { CurrentUsersService } from 'src/app/shared/services/current-users.service';

export interface MemoryFileUpdateEvent {
  memory?: Memory;
  file: File;
}

@Component({
  selector: 'app-platform-files-info',
  templateUrl: './platform-files-info.component.html',
  styleUrls: ['./platform-files-info.component.scss'],
})
export class PlatformFilesInfoComponent {
  @Input() memory?: Memory;
  @Input() showPreview = false;
  @Output() memoryUpdated = new EventEmitter<MemoryFileUpdateEvent>();

  translations: LocalizedDict = {
    info: { EN: 'File Metadata', FR: 'Information sur le fichier' },
    name: { EN: 'Name:', FR: 'Nom:' },
    version: { EN: 'Version:', FR: 'Version:' },
    lastUpdatedAt: { EN: 'Last update:', FR: 'Dernière mise à jour:' },
    fileSize: { EN: 'File size:', FR: 'Poids du fichier:' },
    fileType: { EN: 'Type:', FR: 'Type:' },
    objectKey: { EN: 'Key:', FR: 'Clef:' },
    copyKey: { EN: 'Copy Key', FR: 'Copier' },
    copyUrl: { EN: 'Copy Url', FR: "Copier l'URL" },
    isUpdating: { EN: 'Valid:', FR: 'Valide:' },
    contextID: { EN: 'Context:', FR: 'Context:' },
    publicURI: { EN: 'Public Access:', FR: 'Accès publique:' },
    notPublic: { EN: 'no public url:', FR: 'aucun lien public' },
    'archivvr/bundle': { EN: 'Unity asset bundle', FR: 'Asset bundle Unity' },
    'image/jpeg': 'JPEG',
    'image/jpg': 'JPEG',
    'image/png': 'PNG',
    'model/gltf-binary': '3D model',
    'model/gltf+binary': '3D model',
    update: { EN: 'Upload File', FR: 'Téléverser' },
  };
  localizedDevices = LocalizedDevices;
  isAdmin = false;

  constructor(private currentUserService: CurrentUsersService) {
    this.isAdmin = this.currentUserService.isAdmin;
  }

  uploadFile(files: File[]): void {
    if (files?.[0]) {
      this.memoryUpdated.emit({
        memory: this.memory,
        file: files[0],
      });
    }
  }
}
