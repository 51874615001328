type Join<
  Key,
  Previous,
  TKey extends number | string = string,
> = Key extends TKey
  ? Previous extends TKey
    ? `${Key}${'' extends Previous ? '' : '.'}${Previous}`
    : never
  : never;

type Previous = [never, 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, ...0[]];

/**
 * This utility strongly types the dot notation of TEntity
 * ex: "mySubObject.myKey.myValue" is a valid dot notation for the object
 * {
 *    mySubObject: {
 *      myKey: {
 *        myValue: "hello"
 *      }
 *    }
 * }
 *
 * The TDepth parameter is used to prevent an infinite recursion hated by Typescript (and your CPU).
 * It will limit the depth of the dot notation to the number used.
 * ex: "mySubObject.myKey.myValue" has a depth of 3.
 *      if 2 is used as TDepth, the resulting type will only contain "mySubObject.myKey"
 *      if 4 is used as TDepth, the resulting type will also contain "mySubObject.myKey.myValue.toString"
 *
 * The TKey parameter can be used to whitelist keys of TEntity. By default, it extends string and thus will capture all keys (because a key extends string, by definition)
 * TKey can be a single string literal, or an union of string.
 * if the strings are not keyof TEntity, they will basically be ignored. TKey is not typed as  (keyof TEntity) because we can't use symbols in a string template
 */
export type DotNotation<
  TEntity,
  TDepth extends number = 4,
  TKey extends number | string = string,
> = [TDepth] extends [never]
  ? never
  : TEntity extends object
  ? {
      [Key in keyof TEntity]-?: Key extends TKey
        ? `${Key}` | Join<Key, DotNotation<TEntity[Key], Previous[TDepth]>>
        : never;
    }[keyof TEntity]
  : '';
