import { CoreEntity } from '../../core-entity';
import { LocalizedString } from '../../localization';

export interface UserMetadata extends CoreEntity {
  given_name?: string;
  family_name?: string;

  about?: LocalizedString;
  phone?: string;

  country: string;
  province: string;
  city: string;
  postalCode: string;
  gender: string;
  birthDate: string;

  userID: string;
}
