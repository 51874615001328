import {
  Component,
  Input,
  OnChanges,
  SimpleChanges,
  forwardRef,
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { LocalizedString, SupportedLocals } from '@apophenia/platform';
import { EditableFormItem } from 'src/app/shared/components/dynamic-form-items/_editable-form';

@Component({
  selector: 'app-editable-localized-text',
  templateUrl: './editable-localized-text.component.html',
  styleUrls: ['./editable-localized-text.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => EditableLocalizedTextComponent),
      multi: true,
    },
  ],
})
export class EditableLocalizedTextComponent
  extends EditableFormItem<LocalizedString>
  implements OnChanges
{
  @Input() defaultRows = 5;
  @Input() textStyle?: Partial<CSSStyleDeclaration>;
  @Input() editingStyle?: Partial<CSSStyleDeclaration>;
  @Input() format: 'input' | 'textarea' = 'textarea';

  locals = Object.keys(SupportedLocals) as SupportedLocals[];
  currentLocal = SupportedLocals.EN;

  loaded = false;

  override writeValue(value: LocalizedString): void {
    super.writeValue(value);
    this.setDefaultLanguage();
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (this.loaded || !changes.currentValue?.currentValue) {
      return;
    }
    this.setDefaultLanguage();
  }

  setDefaultLanguage(): void {
    if (
      !this.currentValue?.[SupportedLocals.EN] &&
      !!this.currentValue?.[SupportedLocals.FR]
    ) {
      this.currentLocal = SupportedLocals.FR;
    }
    this.loaded = true;
  }
}
